import {CenterViewContainer} from '../../../welcomeCommon/CenterViewContainer';
import {SingleTenantCheckinQRCode} from '../qr/SingleTenantCheckinQRCode';
import {SingleTenantQRCodeReader} from '../qr/SingleTenantQRCodeReader';
import {useTenantContext} from '../../../../common/context/TenantContext';
import React from 'react';
import {QrCodeOrLabel} from '../../../welcomeCommon/QrCodeOrLabel';

export const SingleTenantCenterViewWithQRCodeAndReader = () => {
  const {tenantData} = useTenantContext();
  return (
    <CenterViewContainer>
      <SingleTenantCheckinQRCode />
      <QrCodeOrLabel companyData={tenantData} />
      <SingleTenantQRCodeReader />
    </CenterViewContainer>
  );
};
