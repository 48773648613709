export class DateUtilities {
  static today(): string {
    const todayDate = new Date().toISOString();
    const dateElements = todayDate.split('T');
    return dateElements[0];
  }

  static padTo2Digits(number: number): string {
    return number.toString().padStart(2, '0');
  }

  static todayAndNow(): string {
    const date = new Date();
    const dateFormat =
      [
        DateUtilities.padTo2Digits(date.getMonth() + 1),
        DateUtilities.padTo2Digits(date.getDate()),
        date.getFullYear(),
      ].join('/') +
      ':' +
      [
        DateUtilities.padTo2Digits(date.getHours()),
        DateUtilities.padTo2Digits(date.getMinutes()),
        DateUtilities.padTo2Digits(date.getSeconds()),
      ].join(':');
    return dateFormat;
  }
}
