import {useContext} from 'react';
import invariant from 'ts-invariant';

import {PubNubContext} from '../context/PubNubContext';

export function usePubNub(): any {
  const context = useContext(PubNubContext);

  invariant(
    context && context.client,
    'No PubNub Client instance can be found. Please ensure that you ' +
      'have called `PubNubProvider` higher up in your tree.',
  );

  return context!.client;
}
