import React from 'react';
import {MultiTenantGridView} from '../leftView/MultiTenantGridView';
import {CenterViewContainer} from '../../../welcomeCommon/CenterViewContainer';
import {MultiTenantQRCodeReader} from '../qr/MultiTenantQRCodeReader';
import {StyleSheet, View} from 'react-native';

export const MultiTenantCenterViewWithQRCodeReader = () => {
  return (
    <View style={styles.mainStack}>
      <CenterViewContainer>
        <View style={styles.leftView}>
          <MultiTenantGridView fullScreen={false} />
        </View>
        <View style={styles.rightView}>
          <MultiTenantQRCodeReader />
        </View>
      </CenterViewContainer>
    </View>
  );
};

const styles = StyleSheet.create({
  mainStack: {
    flex: 1,
  },
  leftView: {
    justifyContent: 'flex-start',
  },
  rightView: {
    marginTop: 130,
  },
});
