import QRCodeReader from '../../../welcomeCommon/QRCodeReader';
import React from 'react';
import {useTenantContext} from '../../../../common/context/TenantContext';

export const SingleTenantQRCodeReader = () => {
  const tenants = useTenantContext();

  return (
    <QRCodeReader
      identifier={'singleTenant_' + tenants.tenantData.company.id}
      translations={tenants.translationsProvider}
      tenantData={tenants.tenantData}
      isCompanyFound={(companyId: string) => {
        return tenants.tenantData.company.id === companyId;
      }}
    />
  );
};
