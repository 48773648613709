import {useStorage} from '../storage/UseStorage';
import {useEffect, useState} from 'react';
import {ICompany} from '@proxyclick/data-model';
import {tenantsKey} from '../storage/StorageKeys';
import {IAuthenticatedApi} from '../api/UseAuthenticatedApi';
import {ITenantData} from '../api/models/ITenantData';
import {useParamsLoader} from '../../features/loading/UseParamsLoader';
import {IPubnubConnector} from '../pubnub/UsePubnubConnector';
import {IKioskInstance} from '@proxyclick/data-model/src/all-models';
import {TenantData} from '../api/models/TenantData';

interface IUseTenantsProps {
  authenticatedApi: IAuthenticatedApi;
  pubnubConnector: IPubnubConnector;
}

export interface IUseTenants {
  tenants: Map<string, ITenantData>;

  currentTenantId: string;

  setCurrentTenantId(tenantId: string): void;
  updatedTenantData: ITenantData;

  initialize(device: IKioskInstance, companyData: ITenantData): Promise<void>;
}

export const useTenants = (props: IUseTenantsProps | null): IUseTenants => {
  const {getItem} = useStorage();
  const [currentTenantId, setCurrentTenantId] = useState<string | null>(null);
  const [tenants, setTenants] = useState<Map<string, ITenantData> | null>(null);
  const [updatedTenantData, setUpdatedTenantData] = useState<ITenantData>(null);
  const {fetchTenants} = props.authenticatedApi;
  const {loadDataForTenant} = useParamsLoader({
    authenticatedApi: props.authenticatedApi,
  });
  const {
    initializePubnub,
    kioskParametersUpdate,
    companyParametersUpdate,
    employeeParametersUpdate,
    translationsUpdate,
    languagesUpdate,
    companyUpdate,
  } = props.pubnubConnector;

  async function initialize(device: IKioskInstance, companyData: ITenantData): Promise<void> {
    if (device && companyData) {
      let fetchedTenants = await downloadTenants(device, companyData);
      const theTenants = new Map<string, ITenantData>();
      for (let i = 0; i < fetchedTenants?.length; i++) {
        const params = await loadDataForTenant(fetchedTenants[i].id);
        const theTenantData: ITenantData = {
          company: fetchedTenants[i],
          ...params,
        };
        theTenants.set(fetchedTenants[i].id, theTenantData);
        if (!device.multiTenant) {
          setCurrentTenantId(theTenantData.company.id);
        }
      }
      setTenants(theTenants);
      await initializePubnub(theTenants, companyData.company);
    }
  }

  async function downloadTenants(device: IKioskInstance, companyData: ITenantData) {
    let fetchedTenants: ICompany[];
    if (device.multiTenant) {
      try {
        fetchedTenants = await fetchTenants(companyData.company.id, JSON.stringify(device.id));
      } catch (e: any) {
        console.log('Error in get tenants: ', e);
      }
    } else {
      if (companyData?.company) {
        fetchedTenants = [companyData.company];
      }
    }
    return fetchedTenants;
  }
  useEffect(() => {
    (async () => {
      const tenantsString: string = await getItem(tenantsKey);
      const storedTenants: Map<string, ITenantData> = JSON.parse(tenantsString);
      if (storedTenants) {
        setTenants(storedTenants);
      }
    })();
  }, []);

  useEffect(() => {
    if (kioskParametersUpdate && tenants) {
      console.log('useTenants: got kiosk parameters updated', kioskParametersUpdate);
      const tenantData = tenants.get(kioskParametersUpdate.companyId);
      if (tenantData) {
        console.log('useTenants: found tenant to update', tenantData);
        const newTenantData = new TenantData(tenantData);
        newTenantData.kioskParameters = kioskParametersUpdate.kioskParameters;
        tenants.set(kioskParametersUpdate.companyId, newTenantData);
        setUpdatedTenantData(newTenantData);
      }
    }
  }, [kioskParametersUpdate]);

  useEffect(() => {
    if (companyParametersUpdate && tenants) {
      const tenant = tenants.get(companyParametersUpdate.companyId);
      if (tenant) {
        tenant.companyParameters = companyParametersUpdate.companyParameters;
      }
    }
  }, [companyParametersUpdate]);

  useEffect(() => {
    if (employeeParametersUpdate && tenants) {
      const tenant = tenants.get(employeeParametersUpdate.companyId);
      if (tenant) {
        tenant.employeeParameters = employeeParametersUpdate.employeeParameters;
      }
    }
  }, [employeeParametersUpdate]);

  useEffect(() => {
    if (translationsUpdate && tenants) {
      const tenant = tenants.get(translationsUpdate.companyId);
      if (tenant) {
        tenant.translations = translationsUpdate.translations;
      }
    }
  }, [translationsUpdate]);

  useEffect(() => {
    if (languagesUpdate && tenants) {
      const tenant = tenants.get(languagesUpdate.companyId);
      if (tenant) {
        tenant.languages = languagesUpdate.languages;
      }
    }
  }, [languagesUpdate]);

  useEffect(() => {
    if (companyUpdate && tenants) {
      const tenant = tenants.get(companyUpdate.companyId);
      if (tenant) {
        tenant.company = companyUpdate.company;
      }
    }
  }, [companyUpdate]);

  return {
    tenants,
    currentTenantId,
    setCurrentTenantId,
    updatedTenantData,
    initialize,
  };
};
