import React from 'react';
import {Platform, View} from 'react-native';
import {AppMode} from './src/common/configuration/IAppMode';
import AppDashboard from './AppDashboard';
import AppStandalone from './AppStandalone';
import {Colors} from '@proxyclick/hello-react-native';
import {log} from './src/common/utils/Log';

export const App = () => {
  const queryString = window?.location?.search;
  const mode = queryString !== null && queryString?.length > 0 ? AppMode.Dashboard : AppMode.Standalone;
  log('mode =', mode);
  const minDimension = Platform.OS === 'ios' ? '100%' : '100vh';

  return (
    <View
      style={{
        minHeight: minDimension,
        minWidth: minDimension,
        backgroundColor: Colors.neutralLight,
      }}>
      {mode === AppMode.Standalone && <AppStandalone />}
      {mode === AppMode.Dashboard && <AppDashboard />}
    </View>
  );
};

export default App;
