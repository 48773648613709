import React from 'react';
import {View, StyleSheet, TextStyle, TouchableOpacity, Text, ViewStyle} from 'react-native';
import {Sizes} from '@proxyclick/hello-react-native';
import {useTenantContext} from '../../../../common/context/TenantContext';

interface CheckinButtonProps {
  onPress: () => void;
  testID?: string;
}

export const CheckinButton = (props: CheckinButtonProps) => {
  const {tenantData} = useTenantContext();
  const {getTextForKey} = useTenantContext().translationsProvider;
  let fontColor = tenantData.kioskParameters.primaryColor;
  let textStyle: TextStyle[] = [styles.text, {color: fontColor}];
  const height = 75;
  const width = 250;
  let buttonStyle: ViewStyle[] = [
    styles.container,
    {
      height: height,
      width: width,
      borderRadius: height / 2,
      backgroundColor: tenantData.kioskParameters.checkinButtonColor,
      borderColor: tenantData.kioskParameters.checkinButtonColor,
      borderWidth: 1,
      borderStyle: 'solid',
    },
  ];

  return (
    <View style={buttonStyle}>
      <TouchableOpacity activeOpacity={1} onPress={props.onPress} testID={'largeCheckinButtonId'}>
        <Text style={textStyle}>{getTextForKey('vm_welcome_checkin_button')}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Sizes.xxl,
  },
  text: {
    fontFamily: 'CircularStd-Bold',
    fontSize: 26,
  },
});
