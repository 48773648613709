import {usePubNub} from '../../services/pubnub';
import {useState} from 'react';
import {CONSTANTS, JSHmac} from 'react-native-hash';
import {log} from '../utils/Log';
import {IAuthenticatedApi} from '../api/UseAuthenticatedApi';
import {useParamsLoader} from '../../features/loading/UseParamsLoader';
import {ITenantData} from '../api/models/ITenantData';
import {ICompany} from '@proxyclick/data-model';
import {IUpdateEvent} from './IUpdateEvent';

export interface IPubnubConnectorProps {
  authenticatedApi: IAuthenticatedApi;
}

export interface IPubnubConnector {
  kioskParametersUpdate: IUpdateEvent | null;
  companyParametersUpdate: IUpdateEvent | null;
  employeeParametersUpdate: IUpdateEvent | null;
  languagesUpdate: IUpdateEvent | null;
  translationsUpdate: IUpdateEvent | null;
  companyUpdate: IUpdateEvent | null;

  initializePubnub(tenants: Map<string, ITenantData>, company: ICompany): Promise<void>;
}

export const usePubnubConnector = (props: IPubnubConnectorProps): IPubnubConnector => {
  const {fetchCompany} = props.authenticatedApi;
  const {
    loadLanguagesForTenant,
    loadEmployeeParametersForTenant,
    loadTranslationsForTenant,
    loadKioskParametersForTenant,
    loadCompanyParametersForTenant,
  } = useParamsLoader({
    authenticatedApi: props.authenticatedApi,
  });
  // TODO: [BEFORE PROD] the below crypto key might be sensitive to leave here.
  const PUBNUB_CRYPTO_KEY = 'Pr0x7Cl1Ck';
  const pubnub = usePubNub();
  const [channels, setChannels] = useState<string[]>([]);
  const [companyIds, setCompanyIds] = useState<string[]>([]);

  const [kioskParametersUpdate, setKioskParametersUpdate] = useState<IUpdateEvent | null>(null);

  const [companyParametersUpdate, setCompanyParametersUpdate] = useState<IUpdateEvent | null>(null);

  const [employeeParametersUpdate, setEmployeeParametersUpdate] = useState<IUpdateEvent | null>(null);

  const [translationsUpdate, setTranslationsUpdate] = useState<IUpdateEvent | null>(null);

  const [languagesUpdate, setLanguagesUpdate] = useState<IUpdateEvent | null>(null);

  const [companyUpdate, setCompanyUpdate] = useState<IUpdateEvent | null>(null);

  async function initializePubnub(tenants: Map<string, ITenantData>, company: ICompany): Promise<void> {
    let companyIsTenant = false;
    const tenantKeys = Array.from(tenants.keys());
    for (let i = 0; i < tenantKeys.length; i++) {
      if (!companyIds.includes(tenantKeys[i])) {
        log('creating channel for ', tenantKeys[i]);
        const channelKey = await createChannelKey(tenantKeys[i]);
        channels.push(channelKey);
        companyIds.push(tenantKeys[i]);
        if (tenantKeys[i] === company.id) {
          companyIsTenant = true;
        }
      }
    }
    if (!companyIsTenant) {
      const channelKey = await createChannelKey(company.id);
      channels.push(channelKey);
      companyIds.push(company.id);
    }
    log('subscribing  channels for ', channels);
    pubnub.subscribe({channels});
    pubnub.addListener({message: handleMessage});
    log('setting channels  ', channels);
    setChannels(channels);
    log('setting companyIds  ', companyIds);
    setCompanyIds(companyIds);
  }

  async function processMessage(message: any) {
    log('got message from pubnub:', message);
    switch (message.type) {
      case 'KioskParametersUpdatedEvent': {
        const kioskParameters = await loadKioskParametersForTenant(message.companyId);
        const update: IUpdateEvent = {
          companyId: message.companyId,
          kioskParameters: kioskParameters,
        } as IUpdateEvent;
        setKioskParametersUpdate(update);
        break;
      }
      case 'ParametersUpdatedEvent': {
        const companyParameters = await loadCompanyParametersForTenant(message.companyId);
        const update: IUpdateEvent = {
          companyId: message.companyId,
          companyParameters: companyParameters,
        } as IUpdateEvent;
        setCompanyParametersUpdate(update);
        break;
      }
      case 'EmployeeParametersUpdatedEvent': {
        const employeeParameters = await loadEmployeeParametersForTenant(message.companyId);
        const update: IUpdateEvent = {
          companyId: message.companyId,
          employeeParameters: employeeParameters,
        } as IUpdateEvent;
        setEmployeeParametersUpdate(update);
        break;
      }
      case 'TranslationsUpdatedEvent': {
        const translations = await loadTranslationsForTenant(message.companyId);
        const update: IUpdateEvent = {
          companyId: message.companyId,
          translations: translations,
        } as IUpdateEvent;
        setTranslationsUpdate(update);
        break;
      }
      case 'LanguagesUpdatedEvent': {
        const languages = await loadLanguagesForTenant(message.companyId);
        const update: IUpdateEvent = {
          companyId: message.companyId,
          translations: languages,
        } as IUpdateEvent;
        setLanguagesUpdate(update);
        break;
      }
      case 'CompanyUpdatedEvent': {
        const company = await fetchCompany(message.companyId);
        const update: IUpdateEvent = {
          companyId: message.companyId,
          company: company,
        } as IUpdateEvent;
        setCompanyUpdate(update);
        break;
      }
      default: {
        log('PubnubConnector: unknown update message', message);
      }
    }
  }

  const handleMessage = async (event: any) => {
    log('handle message from pubnub:', event);
    const message = event.message;
    if (typeof message === 'string' || message.hasOwnProperty('type')) {
      await processMessage(message);
    }
  };

  async function createChannelKey(companyId: string): Promise<string> {
    try {
      const hash: string = await JSHmac(companyId, PUBNUB_CRYPTO_KEY, CONSTANTS.HmacAlgorithms.HmacSHA256);
      const channelKey = 'kiosk-c-' + hash;
      if (
        !channels.find(key => {
          return key === channelKey;
        })
      ) {
        log('channel for ', companyId, 'is new');
      }
      return channelKey;
    } catch (error) {
      log(error);
    }
  }

  // async function unsubscribe() {
  //   if (channels.length > 0) {
  //     pubnub.unsubscribe({channels});
  //     pubnub.removeListener({message: handleMessage});
  //   }
  // }

  return {
    initializePubnub,
    kioskParametersUpdate,
    companyParametersUpdate,
    employeeParametersUpdate,
    translationsUpdate,
    languagesUpdate,
    companyUpdate,
  };
};
