import {useState} from 'react';
import {IApiError} from '../../common/api/UseApi';
import {useActivatedContext} from '../../common/context/IActivatedContext';
import {useStorage} from '../../common/storage/UseStorage';
import {accessTokenKey} from '../../common/storage/StorageKeys';
import {useNavigation} from '@react-navigation/native';
import {IActivateResponse} from '../../common/api/responses/IActivateResponse';

export interface IDeviceActivator {
  activateDevice: (activationKey: string) => Promise<void>;
  error: IApiError | null;
  loading: boolean;
}

export const useDeviceActivator = (): IDeviceActivator => {
  const {navigate} = useNavigation();
  const {storeItem} = useStorage();
  const {activateDeviceWithKey} = useActivatedContext().unauthenticatedApi;
  const {setAccessToken} = useActivatedContext().authenticator;
  const {setDeviceId, setCompanyId} = useActivatedContext().deviceProvider;
  const [error, setError] = useState<IApiError | null>(null);
  const [loading, setLoading] = useState(false);

  async function activateDevice(activationKey: string): Promise<void> {
    try {
      console.log('activateDevice called');
      setLoading(true);
      const rawKey = activationKey.toLowerCase();
      const response: IActivateResponse = await activateDeviceWithKey(rawKey);
      await storeItem(accessTokenKey, response.accessToken);
      setAccessToken(response.accessToken);
      setDeviceId(response.kioskInstanceId);
      setCompanyId(response.companyId);
      navigate('loadKioskScreen');
    } catch (exception: any) {
      let apiError: IApiError = exception;
      console.log('Error during activation:', exception);
      setError(apiError);
    } finally {
      setLoading(false);
    }
  }

  return {activateDevice, error, loading};
};
