import {useStorage} from '../../common/storage/UseStorage';
import {useState} from 'react';
import {languagesKey} from '../../common/storage/StorageKeys';
import {IApiError} from '../../common/api/UseApi';
import {IAuthenticatedApi} from '../../common/api/UseAuthenticatedApi';
import {IKioskLanguage} from '@proxyclick/data-model/src/all-models';
import {log} from '../../common/utils/Log';

export interface ILanguagesLoader {
  load(tenantId: string): Promise<IKioskLanguage[]>;
  error: IApiError;
}
export interface ILanguagesLoaderProps {
  authenticatedApi: IAuthenticatedApi;
}

export const useLanguagesLoader = (props: ILanguagesLoaderProps): ILanguagesLoader => {
  const {storeItem} = useStorage();
  const [error, setError] = useState<IApiError | null>(null);
  const {fetchLanguages} = props.authenticatedApi;

  async function load(tenantId: string): Promise<IKioskLanguage[]> {
    try {
      log('loading languages for ', tenantId);
      const fetchedLanguages = await fetchLanguages(tenantId);
      await storeItem(languagesKey + tenantId, JSON.stringify(fetchedLanguages));
      log('COMPLETED loading languages for ', tenantId);
      return fetchedLanguages;
    } catch (loadError) {
      log('FAILED loading languages for ', tenantId, 'Error: ', loadError);
      setError(loadError);
    }
  }

  return {
    load,
    error,
  };
};
