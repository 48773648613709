import React from 'react';
import {CenterViewContainer} from '../../../welcomeCommon/CenterViewContainer';
import {SingleTenantCheckinQRCode} from '../qr/SingleTenantCheckinQRCode';
import {SingleTenantTitleAndCheckinButton} from '../leftView/SingleTenantTitleAndCheckinButton';

export const SingleTenantCenterViewWithQRCode = () => {
  return (
    <CenterViewContainer>
      <SingleTenantTitleAndCheckinButton />
      <SingleTenantCheckinQRCode />
    </CenterViewContainer>
  );
};
