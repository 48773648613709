import {IHttpClientOptions} from '@proxyclick/http-client';
import {useApi} from './UseApi';
import {ICompany, IKioskParams, IVisit} from '@proxyclick/data-model';
import {ITranslations} from './models/ITranslations';
import {IAuthenticator} from '../authenticator/UseAuthenticator';
import {
  IBadgeSettings,
  IKioskInstance,
  IKioskLanguage,
  IVisitsPageData,
  IVmParams,
} from '@proxyclick/data-model/src/all-models';
import {IEmployeeParams} from '@proxyclick/data-model/src/employee/employee';
import {log} from '../utils/Log';
import {DateUtilities} from '../utils/DateUtilities';
import {IEnvironment} from '../configuration/IEnvironment';
import {useMemo} from 'react';

export interface IAuthenticatedApiProps {
  authenticator: IAuthenticator;
  environment: IEnvironment;
}

export interface IAuthenticatedApi {
  isAuthenticated: boolean;

  fetchCompany(companyId: string): Promise<ICompany>;

  fetchDevice(companyId: string, deviceId: string): Promise<IKioskInstance>;

  fetchTenants(companyId: string, deviceId: string): Promise<ICompany[]>;

  fetchKioskParameters(companyId: string): Promise<IKioskParams>;

  fetchCompanyParameters(companyId: string): Promise<IVmParams>;

  fetchTranslations(companyId: string): Promise<ITranslations>;

  fetchVisitsForToday(companyId: string, page: number, pageSize: number): Promise<IVisitsPageData>;

  fetchVisitById(companyId: string, visitId: string): Promise<IVisit>;

  fetchEmployeeParameters(companyId: string): Promise<IEmployeeParams>;

  checkinForCompanyAndVisit(companyId: string, visitId: string, location: string): Promise<IBadgeSettings>;

  checkoutForCompanyAndVisit(companyId: string, visitId: string): Promise<IBadgeSettings>;

  onSiteForCompanyAndVisit(companyId: string, visitId: string): Promise<IBadgeSettings>;

  fetchLanguages(companyId: string): Promise<IKioskLanguage[]>;
}

export const useAuthenticatedApi = (props: IAuthenticatedApiProps): IAuthenticatedApi => {
  const {accessToken} = props.authenticator;
  const {buildRequest, handleApiError, request} = useApi();
  const {apiBaseUrl} = props.environment;

  function buildHeaders(): {} {
    return {
      'User-Agent': 'Welcome/5.0.0',
      Authorization: 'Bearer ' + accessToken,
    };
  }

  const isAuthenticated: boolean = useMemo(() => {
    return accessToken !== null && accessToken !== undefined && accessToken !== '';
  }, [accessToken]);

  function buildRequestWithAccessToken(method: IHttpClientOptions['method'], url: string, payload?: any) {
    return buildRequest(method, url, buildHeaders(), payload);
  }

  async function fetchTenants(companyId: string, deviceId: string): Promise<ICompany[]> {
    try {
      const url: string = `${apiBaseUrl}app/${companyId}/vm/kiosk/instances/${deviceId}/tenants`;
      const options: IHttpClientOptions = buildRequestWithAccessToken('get', url);
      const response = await request<IKioskInstance[]>(options);
      const tenants: ICompany[] = response.map(device => device.company);
      return tenants;
    } catch (e) {
      handleApiError(e);
    }
  }

  async function fetchCompany(companyId: string): Promise<ICompany> {
    try {
      const url: string = `${apiBaseUrl}app/${companyId}/profile`;
      const options: IHttpClientOptions = buildRequestWithAccessToken('get', url);
      const companyResponse = await request<ICompany>(options);
      return companyResponse;
    } catch (e) {
      handleApiError(e);
    }
  }

  async function fetchDevice(companyId: string, deviceId: string): Promise<IKioskInstance> {
    try {
      const url: string = `${apiBaseUrl}app/${companyId}/vm/kiosk/instances/${deviceId}`;
      const options: IHttpClientOptions = buildRequestWithAccessToken('get', url);
      const deviceResponse = await request<IKioskInstance>(options);
      return deviceResponse;
    } catch (e) {
      handleApiError(e);
    }
  }

  async function fetchKioskParameters(companyId: string): Promise<IKioskParams> {
    try {
      const url: string = `${apiBaseUrl}app/${companyId}/vm/kiosk/params`;
      const options = buildRequestWithAccessToken('get', url);
      let response: IKioskParams = await request<IKioskParams>(options);

      response.primaryColor = '#' + response.primaryColor;
      response.titleColor = '#' + response.titleColor;
      response.themeColor = '#' + response.themeColor;
      response.checkinButtonColor = '#' + response.checkinButtonColor;

      return response;
    } catch (e) {
      handleApiError(e);
    }
  }

  async function fetchCompanyParameters(companyId: string): Promise<IVmParams> {
    try {
      const url: string = `${apiBaseUrl}app/${companyId}/vm/params`;
      const options = buildRequestWithAccessToken('get', url);
      return await request<IVmParams>(options);
    } catch (e) {
      handleApiError(e);
    }
  }

  async function fetchEmployeeParameters(companyId: string): Promise<IEmployeeParams> {
    try {
      const url: string = `${apiBaseUrl}app/${companyId}/em/params`;
      const options = buildRequestWithAccessToken('get', url);
      return await request<IEmployeeParams>(options);
    } catch (e) {
      handleApiError(e);
    }
  }

  async function fetchTranslations(companyId: string): Promise<ITranslations> {
    try {
      const url: string = `${apiBaseUrl}app/${companyId}/vm/kiosk/params/texts`;
      const options = buildRequestWithAccessToken('get', url);
      return await request<ITranslations>(options);
    } catch (e) {
      handleApiError(e);
    }
  }

  async function fetchVisitsForToday(companyId: string, page: number, pageSize: number): Promise<IVisitsPageData> {
    try {
      const url: string = `${apiBaseUrl}app/${companyId}/vm/visits/${DateUtilities.today()}?mode=all&status=1,2,4,5,6&page=${page}&pageSize=${pageSize}`;
      const options = buildRequestWithAccessToken('get', url);
      const response = await request<IVisitsPageData>(options);
      return response;
    } catch (e) {
      handleApiError(e);
    }
  }

  //
  // async function fetchVisitsForMeetingForToday(
  //   companyId: string,
  //   meetingId: string,
  //   page: number,
  //   pageSize: number,
  // ): Promise<IPageData<IVisit>> {
  //   try {
  //     const url: string = `${apiBaseUrl}app/${companyId}/vm/visits/${DateUtilities.today()}/${meetingId}?mode=all&status=1,2,4,5,6&page=${page}&pageSize=${pageSize}`;
  //     const options = buildRequestWithAccessToken('get', url);
  //     const response: IPageData<IVisit> = await request<IPageData<IVisit>>(options);
  //     return response;
  //   } catch (e) {
  //     handleApiError(e);
  //   }
  // }

  // // TODO: POST NEEDS BODY SETUP
  // async function ping(companyId: string, deviceId: string): Promise<IKioskInstance> {
  //   try {
  //     const url: string = `${apiBaseUrl}app/${companyId}/vm/kiosk/instances/${deviceId}/ping`;
  //     const options = buildRequestWithAccessToken('post', url);
  //     const response: IKioskInstance = await request<IKioskInstance>(options);
  //     return response;
  //   } catch (e) {
  //     handleApiError(e);
  //   }
  // }

  // async function fetchHosts(companyId: string, page: number, pageSize: number): Promise<IPageData<IUser>> {
  //   try {
  //     const url: string = `${apiBaseUrl}v1/companies/${companyId}/users?page=${page}&pageSize=${pageSize}`;
  //     const options = buildRequestWithAccessToken('get', url);
  //     const response: IPageData<IUser> = await request<IPageData<IUser>>(options);
  //     return response;
  //   } catch (e) {
  //     handleApiError(e);
  //   }
  // }
  //
  // async function fetchHostById(companyId: string, userId: string): Promise<IUser> {
  //   try {
  //     const url: string = `${apiBaseUrl}app/${companyId}/users/${userId}`;
  //     const options = buildRequestWithAccessToken('get', url);
  //     return await request<IUser>(options);
  //   } catch (e) {
  //     handleApiError(e);
  //   }
  // }

  async function fetchVisitById(companyId: string, visitId: string): Promise<IVisit> {
    try {
      const url: string = `${apiBaseUrl}app/${companyId}/visits/${visitId}`;
      const options = buildRequestWithAccessToken('get', url);
      return await request<IVisit>(options);
    } catch (e) {
      handleApiError(e);
    }
  }

  // async function fetchVisitors(companyId: string, page: number, pageSize: number): Promise<IPageData<IVisitor>> {
  //   try {
  //     const url: string = `${apiBaseUrl}v1/companies/${companyId}/vm/kiosk/visitors?page=${page}&pageSize=${pageSize}`;
  //     const options = buildRequestWithAccessToken('get', url);
  //     const response: IPageData<IVisitor> = await request<IPageData<IVisitor>>(options);
  //     return response;
  //   } catch (e) {
  //     handleApiError(e);
  //   }
  // }
  //
  // async function fetchCustomFlowWithId(companyId: string, flowId: string): Promise<IFlow> {
  //   try {
  //     const url: string = `${apiBaseUrl}app/${companyId}/vm/flows/${flowId}`;
  //     const options = buildRequestWithAccessToken('get', url);
  //     return await request<IFlow>(options);
  //   } catch (e) {
  //     handleApiError(e);
  //   }
  // }

  // async function fetchFieldsWithName(companyId: string, fieldName: string): Promise<IKioskField> {
  //   try {
  //     const url: string = `${apiBaseUrl}app/${companyId}/vm/kiosk/params/fields/${fieldName}`;
  //     const options = buildRequestWithAccessToken('get', url);
  //     return await request<IKioskField>(options);
  //   } catch (e) {
  //     handleApiError(e);
  //   }
  // }
  //
  // async function fetchLanguageWithCode(companyId: string, languageCode: string): Promise<IKioskLanguage> {
  //   try {
  //     const url: string = `${apiBaseUrl}app/${companyId}/vm/kiosk/params/Languages/${languageCode}`;
  //     const options = buildRequestWithAccessToken('get', url);
  //     return await request<IKioskLanguage>(options);
  //   } catch (e) {
  //     handleApiError(e);
  //   }
  // }

  async function fetchLanguages(companyId: string): Promise<IKioskLanguage[]> {
    try {
      const url: string = `${apiBaseUrl}app/${companyId}/vm/kiosk/params/Languages`;
      const options = buildRequestWithAccessToken('get', url);
      return await request<IKioskLanguage[]>(options);
    } catch (e) {
      handleApiError(e);
    }
  }

  // async function fetchSVGBadge(companyId: string): Promise<IBadgeSettings> {
  //   try {
  //     const url: string = `${apiBaseUrl}app/${companyId}/vm/kiosk/params/badge`;
  //     const options = buildRequestWithAccessToken('get', url);
  //     return await request<IBadgeSettings>(options);
  //   } catch (e) {
  //     handleApiError(e);
  //   }
  // }

  // TODO: POST NEEDS BODY SETUP
  // async function addMeeting(companyId: string, meeting: any): Promise<IBadgeSettings> {
  //   try {
  //     const url: string = `${apiBaseUrl}app/${companyId}/vm/meetings`;
  //     const options = buildRequestWithAccessToken('post', url, meeting);
  //     return await request<IBadgeSettings>(options);
  //   } catch (e) {
  //     handleApiError(e);
  //   }
  // }

  // async function fetchApplicationLanguages(): Promise<IKioskLanguage[]> {
  //   try {
  //     const url: string = `${apiBaseUrl}app/languages`;
  //     const options = buildRequestWithAccessToken('get', url);
  //     return await request<IKioskLanguage[]>(options);
  //   } catch (e) {
  //     handleApiError(e);
  //   }
  // }

  async function onSiteForCompanyAndVisit(companyId: string, visitId: string): Promise<IBadgeSettings> {
    try {
      const url: string = `${apiBaseUrl}app/${companyId}/vm/visits/${visitId}/on-site`;
      const options = buildRequestWithAccessToken('post', url);
      return await request<IBadgeSettings>(options);
    } catch (e) {
      handleApiError(e);
    }
  }

  async function checkinForCompanyAndVisit(
    companyId: string,
    visitId: string,
    location: string,
  ): Promise<IBadgeSettings> {
    try {
      const url: string = `${apiBaseUrl}app/${companyId}/vm/visits/${visitId}/check-in`;
      const payload = {
        arrivedAt: new Date().toISOString(),
        location: location,
      };
      const options = buildRequestWithAccessToken('post', url, payload);
      const response = await request<any>(options);
      return response;
    } catch (e) {
      handleApiError(e);
    }
  }

  async function checkoutForCompanyAndVisit(companyId: string, visitId: string): Promise<IBadgeSettings> {
    try {
      const url: string = `${apiBaseUrl}app/${companyId}/vm/visits/${visitId}/check-out`;
      const payload = {
        arrivedAt: new Date().toISOString(),
      };
      log('checkout payload', payload);
      const options = buildRequestWithAccessToken('post', url, payload);
      const response = await request<any>(options);
      log('response from check-out: ', response);
      return response;
    } catch (e) {
      handleApiError(e);
    }
  }

  /*
                                            [Post("/full/app/{companyId}/vm/visits/{visitId}/on-site")]
                                                [Headers("Authorization: Bearer")]
                                            Task OnSiteForCompanyAndVisit(string companyId, string visitId, [Body] OnSitePayLoadDto payload);

                                            [Post("/full/app/{companyId}/vm/visits/{visitId}/check-in")]
                                                [Headers("Authorization: Bearer")]
                                            Task CheckInForCompanyAndVisit(string companyId, string visitId, [Body] ICheckinPayloadDto payload);

                                            [Post("/full/app/{companyId}/vm/visits/{visitId}/check-out")]
                                                [Headers("Authorization: Bearer")]
                                            Task CheckOutForCompanyAndVisit(string companyId, string visitId, [Body] CheckoutPayloadDto payload);

                                            [Put("/full/app/{companyId}/vm/visits/{visitId}/custom-fields")]
                                                [Headers("Authorization: Bearer")]
                                            Task PostCustomFieldsForVisit(string companyId, string visitId, [Body] VisitCustomFieldsPayload payload);

                                            [Get("/full/app/{companyId}/vm/custom-fields")]
                                                [Headers("Authorization: Bearer")]
                                            Task<List<CustomFieldDto>> GetCustomFieldsForTenant(string companyId);

                                            [Post("/full/app/{companyId}/vm/visitors/{visitorId}/send-permanent-pass")]
                                                [Headers("Authorization: Bearer")]
                                            Task SendPermanentPass(string companyId, string visitorId);

                                            [Put("/full/app/{companyId}/vm/visitors/{visitorId}")]
                                                [Headers("Authorization: Bearer")]
                                            Task PostVisitorCustomFields(string companyId, string visitorId, [Body] VisitorCustomFieldsPayload payload);

                                            [Post("/full/app/{companyId}/vm/kiosk/instances/{instanceId}/upload-log")]
                                                [Headers("Authorization: Bearer")]
                                            Task UploadLogFile(string instanceId, string companyId, [Body] PostLogRequest log);

                                            [Multipart]
                                                [Post("/full/documents")]
                                                [Headers("Authorization: Bearer")]
                                            Task<string> PostImage([AliasAs("document")] StreamPart bytes);

                                            [Multipart]
                                                [Put("/full/documents")]
                                                [Headers("Authorization: Bearer", "Content-Type: image/jpeg;")]
                                            Task PutImage([AliasAs("document")] ByteArrayPart bytes);

                                            [Multipart]
                                                [Post("/full/documents")]
                                                [Headers("Authorization: Bearer", "Content-Type: application/pdf;")]
                                            Task PostBadge([AliasAs("badge.pdf")] ByteArrayPart bytes);

                                            [Post("/full/app/{companyId}/dm/deliveries")]
                                                [Headers("Authorization: Bearer")]
                                            Task RegisterDelivery(string companyId, [Body] DeliveryDto deliveryData);

                                            [Post("/full/app/{companyId}/idscan/scan")]
                                                [Headers("Authorization: Bearer")]
                                            Task<IdScanResultDto> IdScan(string companyId, [Body] IdScanPayloadDto payloadDto);

                                            [Post("/full/app/{companyId}/vm/visits/{date}/{meetingId}/{visitorId}/sign-agreement")]
                                                [Headers("Authorization: Bearer")]
                                            Task PostVisitorAgreementSignature(string companyId, string date, string meetingId, string visitorId, [Body] VisitorAgreementSignPayloadDto payloadDto);


                                            // Vaccination
                                            [Get("/private/companies/{companyId}/vm/visitors/{visitorId}/clinical-data")]
                                                [Headers("Authorization: Bearer")]
                                            Task<VisitorClinicalDataDto> GetVisitorClinicalData(string companyId, string visitorId);

                                            // Vaccination
                                            [Put("/private/companies/{companyId}/vm/visitors/{visitorId}/clinical-data")]
                                                [Headers("Authorization: Bearer")]
                                            Task SendVisitorClinicalData(string companyId, string visitorId, [Body] VisitorClinicalData clinicalData);
                                          }
                                          }*/

  return {
    isAuthenticated,
    fetchCompany,
    fetchDevice,
    fetchTenants,
    fetchKioskParameters,
    fetchCompanyParameters,
    fetchTranslations,
    fetchVisitsForToday,
    fetchVisitById,
    fetchEmployeeParameters,
    fetchLanguages,
    checkinForCompanyAndVisit,
    checkoutForCompanyAndVisit,
    onSiteForCompanyAndVisit,
  };
};
