import {useStorage} from '../../common/storage/UseStorage';
import {useState} from 'react';
import {ICompanyParametersDataLoader, ICompanyParametersLoaderProps} from './ICompanyParametersLoader';
import {companyParametersKey} from '../../common/storage/StorageKeys';
import {IVmParams} from '@proxyclick/data-model/src/all-models';
import {IApiError} from '../../common/api/UseApi';
import {log} from '../../common/utils/Log';

export const useCompanyParametersLoader = (props: ICompanyParametersLoaderProps): ICompanyParametersDataLoader => {
  const {storeItem} = useStorage();
  const [error, setError] = useState<IApiError | null>(null);
  const {fetchCompanyParameters} = props.authenticatedApi;

  async function load(tenantId: string): Promise<IVmParams> {
    try {
      log('loading company parameters for ', tenantId);
      const theCompanyParameters = await fetchCompanyParameters(tenantId);
      await storeItem(companyParametersKey + tenantId, JSON.stringify(theCompanyParameters));
      log('COMPLETED loading company parameters for ', tenantId);
      return theCompanyParameters;
    } catch (loadError) {
      log('FAILED loading company parameters for ', tenantId, 'Error', loadError);
      throw setError(loadError);
    }
  }

  return {
    load,
    error,
  };
};
