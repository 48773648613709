import {useEffect, useState} from 'react';
import {useActivatedContext} from '../../../common/context/IActivatedContext';

export interface IUseCenterViewLayout {
  showQRCode: boolean;
  showQRCodeReader: boolean;
  showCheckinQRCodeAndQRCodeReader: boolean;
}

export const useCenterViewLayoutForMultiTenant = () => {
  const {companyData} = useActivatedContext().companyDataProvider;
  const [showQRCode, setShowQRCode] = useState<boolean>(
    companyData.kioskParameters.touchlessUnexpectedEnabled || companyData.employeeParameters.enabled,
  );
  const [showQRCodeReader, setShowQRCodeReader] = useState<boolean>(
    companyData.kioskParameters.touchlessExpectedEnabled,
  );

  const [showCheckinQRCodeAndQRCodeReader, setShowCheckinQRCodeAndQRCodeReader] = useState<boolean>(
    showQRCode && showQRCodeReader,
  );

  useEffect(() => {
    setShowQRCode(companyData.kioskParameters.touchlessUnexpectedEnabled || companyData.employeeParameters.enabled);
    setShowQRCodeReader(companyData.kioskParameters.touchlessExpectedEnabled);
    setShowCheckinQRCodeAndQRCodeReader(
      companyData.kioskParameters.touchlessExpectedEnabled &&
        (companyData.kioskParameters.touchlessUnexpectedEnabled || companyData.employeeParameters.enabled),
    );
  }, [companyData.kioskParameters, companyData.employeeParameters]);

  return {
    showQRCode,
    showQRCodeReader,
    showCheckinQRCodeAndQRCodeReader,
  };
};
