import React from 'react';
import {MultiTenantGridView} from '../leftView/MultiTenantGridView';
import {CenterViewContainer} from '../../../welcomeCommon/CenterViewContainer';
import {MultiTenantCheckinQRCode} from '../qr/MultiTenantCheckinQRCode';
import {StyleSheet, View} from 'react-native';

export const MultiTenantCenterViewWithQRCode = () => {
  return (
    <View style={styles.mainStack}>
      <CenterViewContainer>
        <View style={styles.leftView}>
          <MultiTenantGridView fullScreen={false} />
        </View>
        <View style={styles.rightView}>
          <MultiTenantCheckinQRCode />
        </View>
      </CenterViewContainer>
    </View>
  );
};
const styles = StyleSheet.create({
  mainStack: {
    flex: 1,
  },
  leftView: {
    marginLeft: -5,
    justifyContent: 'flex-start',
  },
  rightView: {
    justifyContent: 'flex-end',
    marginTop: 170,
  },
});
