import React from 'react';
import {PubNubProvider} from './src/services/pubnub/context/PubNubProvider';

import App from './App';
import PubnubConfigResolver from './src/services/pubnub/PubnubConfigResolver';

const pubnubConfigResolver = new PubnubConfigResolver();
const pubNubClient = pubnubConfigResolver.resolvePubnubConfig();

export const Application = () => (
  <PubNubProvider client={pubNubClient}>
    <App />
  </PubNubProvider>
);
