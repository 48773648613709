import React from 'react';
import {ScreenWithBackground} from '../welcomeCommon/ScreenWithBackground';
import {StyleSheet, View} from 'react-native';
import {WelcomeCenterView} from './components/centerViews/WelcomeCenterView';
import {Sizes} from '@proxyclick/hello-react-native';
import {BottomBar} from './components/bottomBar/BottomBar';
import {useCenterViewLayoutForSingleTenant} from './components/UseCenterViewLayoutForSingleTenant';
import {WelcomeTitle} from '../welcomeCommon/WelcomeTitle';
import {useTranslationsProvider} from '../../common/translations/useTranslationsProvider';
import {useTenantContext} from '../../common/context/TenantContext';

export const WelcomeScreen = () => {
  console.log('Welcome screen');
  const {tenantData} = useTenantContext();
  const translations = useTranslationsProvider({tenantData: tenantData});
  const {showCheckinQRCodeAndQRCodeReader} = useCenterViewLayoutForSingleTenant();
  return (
    <ScreenWithBackground>
      <View style={styles.mainStackView} testID={'homeViewId'}>
        {showCheckinQRCodeAndQRCodeReader && (
          <WelcomeTitle translations={translations} tenantData={tenantData} multiLine={false} />
        )}
        <WelcomeCenterView />
        <BottomBar />
      </View>
    </ScreenWithBackground>
  );
};
const styles = StyleSheet.create({
  mainStackView: {
    flex: 1,
    flexDirection: 'column',
    marginTop: Sizes.m,
    marginBottom: Sizes.m,
    marginHorizontal: Sizes.xxl,
    justifyContent: 'center',
  },
  topBar: {
    height: Sizes.xxl,
  },
});
