import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import React from 'react';
import {IMultiTenantCellProps} from './IMultiTenantCell';
import {multiTenantCellStyles} from './MultiTenantCellStyles';
import {useNavigation} from '@react-navigation/native';
import {useActivatedContext} from '../../../../common/context/IActivatedContext';

export const MultiTenantCell = (props: IMultiTenantCellProps) => {
  const {navigate} = useNavigation();
  const {company} = props;
  const {setCurrentTenantId} = useActivatedContext().tenants;
  return (
    <View style={multiTenantCellStyles.cellContainer}>
      <TouchableOpacity
        onPress={() => {
          setCurrentTenantId(company.id);
          navigate('kioskNavigator');
        }}
        style={styles.touchableArea}>
        <View style={styles.companyContainer}>
          {company.logo128 && <Image style={multiTenantCellStyles.imageThumbnail} source={{uri: company.logo128}} />}
          {!company.logo128 && <Text>{company.name}</Text>}
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  companyContainer: {
    flex: 1,
  },
  touchableArea: {flex: 1},
});
