import {IPubnubConnector} from '../pubnub/UsePubnubConnector';
import {useState} from 'react';
import {ICompany} from '@proxyclick/data-model';
import {IAuthenticatedApi} from '../api/UseAuthenticatedApi';
import {IKioskInstance} from '@proxyclick/data-model/src/all-models';
import {IDeviceProvider} from './UseDevice';

interface IDeviceAndCompanyProviderProps {
  companyId: string;
  pubnubConnector: IPubnubConnector;
  authenticatedApi: IAuthenticatedApi;
}

export const useFakeDevice = (props: IDeviceAndCompanyProviderProps | null): IDeviceProvider => {
  const [deviceId, setDeviceId] = useState<string | null>(null);
  const [device, setDevice] = useState<IKioskInstance | null>(null);
  const [companyId, setCompanyId] = useState(props.companyId);
  const {fetchCompany} = props.authenticatedApi;

  async function initialize(): Promise<IKioskInstance> {
    const fetchedCompany: ICompany = await fetchCompany(companyId);
    const fakeDevice: IKioskInstance = {
      id: 0,
      name: 'na',
      license: 'na',
      location: 'na',
      multiTenantTitle: 'na',
      company: fetchedCompany,
      platform: 'BROWSER',
      userAgent: 'na',
      deviceToken: 'na',
      multiTenant: false,
      createdAt: 'na',
      lastModifiedAt: 'na',
      firstActivatedAt: 'na',
      lastActivatedAt: 'na',
      status: 'OPEN',
      lastPingAt: 'na',
      deviceOSVersion: 'na',
      deviceName: 'na',
      version: 'na',
    };
    setDevice(fakeDevice);
    return fakeDevice;
  }

  return {
    device,
    setDeviceId,
    setCompanyId,
    initialize,
  };
};
