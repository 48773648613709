import {ITenantData} from './ITenantData';
import {ICompany, IKioskParams} from '@proxyclick/data-model';
import {IKioskLanguage, IVmParams} from '@proxyclick/data-model/src/all-models';
import {IEmployeeParams} from '@proxyclick/data-model/src/employee/employee';
import {ITranslations} from './ITranslations';

export class TenantData implements ITenantData {
  constructor(tenantData: ITenantData) {
    this.clone(tenantData);
  }
  clone(tenantData: ITenantData): void {
    this.company = tenantData.company;
    this.companyParameters = tenantData.companyParameters;
    this.kioskParameters = tenantData.kioskParameters;
    this.employeeParameters = tenantData.employeeParameters;
    this.translations = tenantData.translations;
    this.languages = tenantData.languages;
  }
  company: ICompany;
  companyParameters: IVmParams;
  kioskParameters: IKioskParams;
  employeeParameters: IEmployeeParams;
  translations: ITranslations;
  languages: IKioskLanguage[];
}
