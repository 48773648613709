import React, {useEffect} from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {ActivateKioskScreen} from '../../features/activateKiosk/ActivateKioskScreen';
import {ProxyclickLogo} from '../../assets/icons/Icons';
import {LoadingScreen} from '../../features/loading/LoadingScreen';
import {useActivatedContext} from '../context/IActivatedContext';
import {useNavigation} from '@react-navigation/native';

export type AuthenticationNavigatorParamList = {
  activateKiosk: undefined;
  loadKioskScreen: undefined;
};

declare global {
  namespace ReactNavigation {
    interface RootParamList extends AuthenticationNavigatorParamList {}
  }
}

const Stack = createStackNavigator<AuthenticationNavigatorParamList>();
export const UnactivatedNavigator = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="activateKiosk"
        component={ActivateKioskScreen}
        options={{
          headerShown: true,
          animationEnabled: false,
          title: 'Activate your kiosk',
          headerRight: () => <ProxyclickLogo />,
        }}
      />
      <Stack.Screen
        name="loadKioskScreen"
        component={LoadingScreen}
        options={{
          headerShown: true,
          animationEnabled: false,
          title: 'Loading kiosk',
        }}
      />
    </Stack.Navigator>
  );
};
