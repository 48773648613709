import React, {useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import {Colors, Sizes} from '@proxyclick/hello-react-native';
import {QrSubText} from './QrSubText';
import {ITranslationsProvider} from '../../common/translations/useTranslationsProvider';
import {useCheckinQRGenerator} from './UseCheckinQRGenerator';
import {ITenantData} from '../../common/api/models/ITenantData';
import {QR_AREA_MARGIN_SIZE_NATIVE} from './QRReaderStyles';

const QR_CODE_SIZE = 300;
export const QR_CODE_CONTAINER_SIZE = 364;

export interface IQRCodeProps {
  tenantData: ITenantData;
  isMultiTenant: boolean;
  translations: ITranslationsProvider;
}

export const CheckinQRCode = (props: IQRCodeProps) => {
  const {isMultiTenant} = props;
  const {qrValueForTenant, qrValueForMultiTenant, setEmployeeTenantId} = useCheckinQRGenerator({
    tenantData: props.tenantData,
  });
  const {getTextForKey} = props.translations;

  useEffect(() => {
    if (props) {
      setEmployeeTenantId(props.tenantData.company.id);
    }
  }, []);

  return (
    <View style={stylesLocal.container}>
      <View style={stylesLocal.qrContainer}>
        {!isMultiTenant && qrValueForTenant && <QRCode size={QR_CODE_SIZE} value={qrValueForTenant} />}
        {isMultiTenant && qrValueForMultiTenant && <QRCode size={QR_CODE_SIZE} value={qrValueForMultiTenant} />}
      </View>
      {props.tenantData.kioskParameters.touchlessUnexpectedEnabled && !props.tenantData.employeeParameters.enabled && (
        <View style={stylesLocal.subtextViewWeb}>
          <QrSubText
            companyData={props.tenantData}
            subText={getTextForKey('vm_welcome_touchless_unexpected_qrCode_show')}
          />
        </View>
      )}
      {!props.tenantData.kioskParameters.touchlessUnexpectedEnabled && props.tenantData.employeeParameters.enabled && (
        <View style={stylesLocal.subtextViewWeb}>
          <QrSubText
            companyData={props.tenantData}
            subText={getTextForKey('vm_welcome_touchless_employee_qrCode_show')}
          />
        </View>
      )}
      {props.tenantData.kioskParameters.touchlessUnexpectedEnabled && props.tenantData.employeeParameters.enabled && (
        <View style={stylesLocal.subtextViewWeb}>
          <QrSubText
            companyData={props.tenantData}
            subText={getTextForKey('vm_welcome_touchless_unexpected_and_employee_qrCode_show')}
          />
        </View>
      )}
    </View>
  );
};

const stylesLocal = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: QR_CODE_CONTAINER_SIZE,
  },
  qrContainer: {
    borderRadius: Sizes.m * 2,
    width: QR_CODE_CONTAINER_SIZE,
    height: QR_CODE_CONTAINER_SIZE,
    backgroundColor: Colors.brandWhite,
    justifyContent: 'center',
    alignItems: 'center',
  },
  subtextViewWeb: {
    maxWidth: QR_CODE_CONTAINER_SIZE,
    paddingTop: QR_AREA_MARGIN_SIZE_NATIVE,
    justifyContent: 'flex-end',
  },
});
