import {QR_READER_VIEWPORT_SIZE} from './QRReaderStyles';
import {QrReader as Scanner} from '@blackbox-vision/react-qr-reader';
import React from 'react';
import {Result} from '@zxing/library';

export interface QrCodeScannerProps {
  identifier: string;
  onRead: (data: string | null) => void;
}

export const QRCodeScanner = (props: QrCodeScannerProps) => {
  const handleScan = async (result: Result | null) => {
    const scannedText = result ? result.getText() : null;
    props.onRead(scannedText);
  };

  return (
    <Scanner
      videoId={props.identifier}
      constraints={{
        facingMode: 'environment',
        width: QR_READER_VIEWPORT_SIZE,
        height: QR_READER_VIEWPORT_SIZE,
      }}
      onResult={handleScan}
    />
  );
};
