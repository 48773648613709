import React, {useEffect} from 'react';
import {Provider as PaperProvider} from 'react-native-paper';
import {ActivatedContextProvider, IActivatedContext} from './src/common/context/IActivatedContext';
import {DashboardActivatedContext} from './src/common/context/DashboardActivatedContext';
import {LoadingScreen} from './src/features/loading/LoadingScreen';
import {KioskNavigator} from './src/common/navigation/KioskNavigator';
import {NavigationContainer} from '@react-navigation/native';
import {log} from './src/common/utils/Log';
import {useFakeDevice} from './src/common/configuration/UseFakeDevice';

const CO_KEY = 'CO';
const TOKEN_KEY = 'token';
const SCREEN_KEY = 'screen';

export const AppDashboard = () => {
  const queryString = window?.location?.search;
  const urlParams = new URLSearchParams(queryString);
  const companyIdParameter = urlParams.get(CO_KEY);
  const tokenParameter = urlParams.get(TOKEN_KEY);
  const screenParameter = urlParams.get(SCREEN_KEY);

  const activatedContext: IActivatedContext = DashboardActivatedContext({
    token: tokenParameter,
    companyId: companyIdParameter,
    screen: screenParameter,
  });

  let content: JSX.Element = <LoadingScreen />;

  if (activatedContext.initialized && activatedContext.tenants) {
    log('context initialized');
    content = <KioskNavigator />;
  }

  return (
    <ActivatedContextProvider value={activatedContext}>
      <PaperProvider>
        <NavigationContainer>{content}</NavigationContainer>
      </PaperProvider>
    </ActivatedContextProvider>
  );
};

export default AppDashboard;
