import Popover, {PopoverPlacement} from 'react-native-popover-view';
import {FlatList, StyleSheet, Text, TextStyle, TouchableOpacity, View} from 'react-native';
import {ChangeLanguageIcon} from '../../../../assets/icons/Icons';
import CountryFlag from 'react-native-country-flag';
import React, {useState} from 'react';
import {Colors, TextStyles} from '@proxyclick/hello-react-native';
import {useTenantContext} from '../../../../common/context/TenantContext';

export const LanguageSelector = () => {
  const {tenantData} = useTenantContext();
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const {getTextForKey, setLanguage} = useTenantContext().translationsProvider;
  const languages = tenantData.languages;
  let textStyle: TextStyle[] = [styles.text, {color: tenantData.kioskParameters.titleColor}];

  function isoCodeForLanguageCode(languageCode: string) {
    switch (languageCode) {
      case 'zh-TW':
        return 'tw';
      case 'zh':
        return 'cn';
      case 'uk':
        return 'ua';
      case 'ja':
        return 'jp';
      case 'ko':
        return 'kr';
      case 'en-US':
        return 'us';
      case 'en':
        return 'gb';
      case 'el':
        return 'gr';
      case 'da':
        return 'gl';
      case 'cs':
        return 'cz';
      default:
        return languageCode;
    }
  }

  return (
    <Popover
      popoverStyle={styles.popOver}
      isVisible={showPopover}
      debug={true}
      placement={PopoverPlacement.TOP}
      onRequestClose={() => setShowPopover(false)}
      from={
        <TouchableOpacity style={styles.showLanguageButton} activeOpacity={1} onPress={() => setShowPopover(true)}>
          <View style={styles.iconLeft}>
            <ChangeLanguageIcon width={25} height={25} stroke={tenantData.kioskParameters.titleColor} />
          </View>
          <Text style={textStyle}>Language</Text>
        </TouchableOpacity>
      }>
      <FlatList
        bounces={true}
        style={styles.list}
        data={languages}
        renderItem={i => {
          const language = i.item;
          return (
            <TouchableOpacity
              key={language.code}
              style={styles.itemButton}
              onPress={() => {
                setLanguage(language.code);
                setShowPopover(false);
              }}>
              <View style={styles.countryContainer}>
                <CountryFlag style={styles.flag} isoCode={isoCodeForLanguageCode(language.code)} size={15} />
                <Text style={styles.languageDisplayName}>{language.displayName}</Text>
              </View>
            </TouchableOpacity>
          );
        }}
      />
    </Popover>
  );
};

const styles = StyleSheet.create({
  list: {
    maxHeight: 420,
  },
  text: {
    textAlign: 'right',
    fontFamily: 'CircularStd-Book',
    fontWeight: 'normal',
    ...TextStyles.textMediumS,
  },
  languageDisplayName: {
    marginLeft: 10,
    marginRight: 5,
    justifyContent: 'center',
    alignSelf: 'center',
    fontFamily: 'CircularStd-Bold',
    fontWeight: 'bold',
    fontSize: 15,
  },
  flag: {
    justifyContent: 'center',
    alignSelf: 'center',
  },
  iconLeft: {
    // TODO: this size should be found in hello-component library
    marginRight: 7,
  },
  showLanguageButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: 40,
  },
  itemButton: {backgroundColor: '#f1f1f100'},
  countryContainer: {flexDirection: 'row', height: 44},
  popOver: {
    borderRadius: 10,
    padding: 5,
    backgroundColor: Colors.neutralLighter,
  },
});
