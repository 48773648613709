import {useEffect, useState} from 'react';
import {useActivatedContext} from '../../common/context/IActivatedContext';
import {ITenantData} from '../../common/api/models/ITenantData';

export interface ICheckinQRGeneratorProps {
  tenantData: ITenantData;
}

export interface ICheckinQRGenerator {
  qrValueForTenant: string;
  qrValueForMultiTenant: string;

  setEmployeeTenantId(companyId: string): void;
}

export const useCheckinQRGenerator = (props: ICheckinQRGeneratorProps): ICheckinQRGenerator => {
  const [employeeTenantId, setEmployeeTenantId] = useState<string | null>(null);
  const [qrValueForTenant, setQRValueForTenant] = useState<string | null>(null);
  const [qrValueForMultiTenant, setQRValueForMultiTenant] = useState<string | null>(null);

  const {device} = useActivatedContext().deviceProvider;

  async function buildQRCodeContentForCompany(companyId: string): Promise<string> {
    const qrCodeContent = props.tenantData.employeeParameters.baseUrl + '&companyId=' + companyId;
    return qrCodeContent;
  }

  async function buildQRCodeContentForHomeScreen(companyId: string): Promise<string> {
    const qrCodeContent: string = await buildQRCodeContentForCompany(companyId);
    return qrCodeContent;
  }

  async function buildQRCodeContentForMultiTenantScreen(companyId: string): Promise<string> {
    let qrCodeContent: string = await buildQRCodeContentForCompany(companyId);
    qrCodeContent += '&kioskId=' + device.id;
    return qrCodeContent;
  }

  useEffect(() => {
    (async () => {
      if (employeeTenantId) {
        setQRValueForTenant(await buildQRCodeContentForHomeScreen(employeeTenantId));
      }
    })();
  }, [employeeTenantId]);

  useEffect(() => {
    (async () => {
      if (device) {
        setQRValueForMultiTenant(await buildQRCodeContentForMultiTenantScreen(device.company.id));
      }
    })();
  }, [device]);

  return {qrValueForTenant, qrValueForMultiTenant, setEmployeeTenantId};
};
