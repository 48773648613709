import {StyleSheet} from 'react-native';

export const QR_READER_VIEWPORT_SIZE = 300;
export const QR_AREA_MARGIN_SIZE_NATIVE = 32;

export const styles = StyleSheet.create({
  containerView: {
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginTop: QR_AREA_MARGIN_SIZE_NATIVE + 10,
    width: QR_READER_VIEWPORT_SIZE,
    marginRight: QR_AREA_MARGIN_SIZE_NATIVE,
  },
  qrReaderContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: QR_READER_VIEWPORT_SIZE,
    height: QR_READER_VIEWPORT_SIZE,
  },
  qrReaderViewPort: {
    width: QR_READER_VIEWPORT_SIZE,
    height: QR_READER_VIEWPORT_SIZE,
    marginBottom: QR_AREA_MARGIN_SIZE_NATIVE,
  },
  qrArea: {
    marginTop: -QR_AREA_MARGIN_SIZE_NATIVE / 2,
    marginLeft: -(QR_AREA_MARGIN_SIZE_NATIVE / 2),
  },

  qrAreaWeb: {
    height: QR_READER_VIEWPORT_SIZE + QR_AREA_MARGIN_SIZE_NATIVE,
    width: QR_READER_VIEWPORT_SIZE + QR_AREA_MARGIN_SIZE_NATIVE,
    marginTop: -QR_READER_VIEWPORT_SIZE - QR_AREA_MARGIN_SIZE_NATIVE - QR_AREA_MARGIN_SIZE_NATIVE / 2,
  },
  subtextView: {
    paddingTop: QR_AREA_MARGIN_SIZE_NATIVE / 2,
  },

  subtextViewWeb: {
    paddingTop: QR_AREA_MARGIN_SIZE_NATIVE,
  },
});
