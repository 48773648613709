import React from 'react';
import {View, StyleSheet} from 'react-native';
export const LoadingScreen = () => {
  console.log('loading screen');
  return <View style={styles.container} />;
};

const styles = StyleSheet.create({
  container: {flex: 1, backgroundColor: '#FFFFFF'},
});
