import {useEffect, useState} from 'react';
import {ICompany} from '@proxyclick/data-model';
import {IAuthenticatedApi} from '../api/UseAuthenticatedApi';
import {ITenantData} from '../api/models/ITenantData';
import {useParamsLoader} from '../../features/loading/UseParamsLoader';
import {IPubnubConnector} from '../pubnub/UsePubnubConnector';
import {log} from '../utils/Log';
import {IKioskInstance} from '@proxyclick/data-model/src/all-models';
import {TenantData} from '../api/models/TenantData';

interface IUseTenantsProps {
  authenticatedApi: IAuthenticatedApi;
  pubnubConnector: IPubnubConnector;
}

export interface IUseTenants {
  tenants: Map<string, ITenantData>;
  currentTenantId: string;
  setCurrentTenantId(tenantId: string): void;
  updatedTenantData: ITenantData;
  initialize(device: IKioskInstance, companyData: ITenantData): Promise<void>;
}

export const useFakeTenants = (props: IUseTenantsProps | null): IUseTenants => {
  const [currentTenantId, setCurrentTenantId] = useState<string | null>(null);
  const [tenants, setTenants] = useState<Map<string, ITenantData> | null>(null);
  const [updatedTenantData, setUpdatedTenantData] = useState<ITenantData>(null);
  const {loadDataForTenant} = useParamsLoader({
    authenticatedApi: props.authenticatedApi,
  });
  const {
    initializePubnub,
    kioskParametersUpdate,
    companyParametersUpdate,
    employeeParametersUpdate,
    translationsUpdate,
    languagesUpdate,
    companyUpdate,
  } = props.pubnubConnector;

  async function initialize(device: IKioskInstance, companyData: ITenantData): Promise<void> {
    if (device && companyData) {
      log('loading tenants: ', device, companyData);
      let fetchedTenants = downloadTenants(companyData);
      const theTenants = new Map<string, ITenantData>();
      const params = await loadDataForTenant(fetchedTenants[0].id);
      const theTenantData: ITenantData = {
        company: fetchedTenants[0],
        ...params,
      };
      theTenants.set(fetchedTenants[0].id, theTenantData);
      setCurrentTenantId(theTenantData.company.id);
      setTenants(theTenants);
      await initializePubnub(theTenants, companyData.company);
    }
  }

  function downloadTenants(companyData: ITenantData) {
    let fetchedTenants: ICompany[];
    if (companyData?.company) {
      fetchedTenants = [companyData.company];
    }
    return fetchedTenants;
  }

  useEffect(() => {
    if (kioskParametersUpdate && tenants) {
      console.log('useTenants: got kiosk parameters updated', kioskParametersUpdate);
      const tenantData = tenants.get(kioskParametersUpdate.companyId);
      if (tenantData) {
        console.log('useTenants: found tenant to update', tenantData);
        const newTenantData = new TenantData(tenantData);
        newTenantData.kioskParameters = kioskParametersUpdate.kioskParameters;
        tenants.set(kioskParametersUpdate.companyId, newTenantData);
        setUpdatedTenantData(newTenantData);
      }
    }
  }, [kioskParametersUpdate]);

  useEffect(() => {
    if (companyParametersUpdate && tenants) {
      const tenant = tenants.get(companyParametersUpdate.companyId);
      if (tenant) {
        tenant.companyParameters = companyParametersUpdate.companyParameters;
      }
    }
  }, [companyParametersUpdate]);

  useEffect(() => {
    if (employeeParametersUpdate && tenants) {
      const tenant = tenants.get(employeeParametersUpdate.companyId);
      if (tenant) {
        tenant.employeeParameters = employeeParametersUpdate.employeeParameters;
      }
    }
  }, [employeeParametersUpdate]);

  useEffect(() => {
    if (translationsUpdate && tenants) {
      const tenant = tenants.get(translationsUpdate.companyId);
      if (tenant) {
        tenant.translations = translationsUpdate.translations;
      }
    }
  }, [translationsUpdate]);

  useEffect(() => {
    if (languagesUpdate && tenants) {
      const tenant = tenants.get(languagesUpdate.companyId);
      if (tenant) {
        tenant.languages = languagesUpdate.languages;
      }
    }
  }, [languagesUpdate]);

  useEffect(() => {
    if (companyUpdate && tenants) {
      const tenant = tenants.get(companyUpdate.companyId);
      if (tenant) {
        tenant.company = companyUpdate.company;
      }
    }
  }, [companyUpdate]);

  return {
    tenants,
    currentTenantId,
    setCurrentTenantId,
    updatedTenantData,
    initialize,
  };
};
