import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {WelcomeScreen} from '../../features/singleTenantWelcome/WelcomeScreen';
import {TenantContext, TenantContextProvider} from '../context/TenantContext';
import {KioskNavigatorParamList} from './MultiTenantKioskNavigator';

declare global {
  namespace ReactNavigation {
    interface RootParamList extends KioskNavigatorParamList {}
  }
}

const Stack = createStackNavigator<KioskNavigatorParamList>();
export const KioskNavigator = () => {
  const tenantContext = TenantContext();
  const {tenantData} = tenantContext;
  return (
    <TenantContextProvider value={tenantContext}>
      <Stack.Navigator>
        <Stack.Screen
          name="welcome"
          component={WelcomeScreen}
          options={{
            headerShown: tenantData.kioskParameters.showLogoMode === 'ALL',
            animationEnabled: false,
          }}
        />
      </Stack.Navigator>
    </TenantContextProvider>
  );
};
