import {ITranslationsProvider, useTranslationsProvider} from '../translations/useTranslationsProvider';
import React, {useContext, useEffect, useReducer} from 'react';
import {ITenantData} from '../api/models/ITenantData';
import {useActivatedContext} from './IActivatedContext';

const context = React.createContext<ITenantContext>({} as ITenantContext);

export interface ITenantContext {
  translationsProvider: ITranslationsProvider;
  tenantData: ITenantData;
}

export const TenantContext = (): ITenantContext => {
  const tenantsHook = useActivatedContext().tenants;
  const {updatedTenantData} = tenantsHook;
  const [currentTenantData, dispatch] = useReducer(
    tenantDataReducer,
    tenantsHook.tenants.get(tenantsHook.currentTenantId),
  );
  const translationsProvider = useTranslationsProvider({tenantData: currentTenantData});

  function tenantDataReducer(tenantData: ITenantData, action: any) {
    return action.tenantData;
  }

  useEffect(() => {
    console.log('TenantContext: set current tenant data');
    if (updatedTenantData !== null && updatedTenantData.company.id == currentTenantData.company.id) {
      console.log('dispatching update to tenantData', updatedTenantData);
      dispatch({
        type: 'updated',
        tenantData: updatedTenantData,
      });
    }
  }, [updatedTenantData]);

  return {
    translationsProvider: translationsProvider,
    tenantData: currentTenantData,
  };
};

export const TenantContextProvider = (props: React.PropsWithChildren<{value: ITenantContext}>) => {
  return <context.Provider value={props.value}>{props.children}</context.Provider>;
};

export function useTenantContext(): ITenantContext {
  return useContext(context);
}
