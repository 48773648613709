class EnvironmentResolver {
  HOSTNAME_INDEX: number = 4;
  HOST_INDEX: number = 2;
  APP_ENVIRONMENT_INDEX: number = 1;
  APP_ENVIRONMENT_SUFFIX_INDEX: number = 1;

  resolveEnvironment(): string {
    let environment = '';
    const fullUrl = window?.location?.href;
    console.log('url = ', fullUrl);
    if (fullUrl !== undefined) {
      const urlPattern = RegExp('^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?');
      const hostPattern = RegExp('^(.*)[.](.*)[.]com');
      const appEnvironmentPattern = RegExp('^.*-(.*)');
      const appSubEnvironmentPattern = RegExp('^app(-)*(.*)');

      const urlMatches = fullUrl.match(urlPattern);
      const hostname = urlMatches[this.HOSTNAME_INDEX];

      const hostMatches = hostname.match(hostPattern);
      console.log('hostname matches', hostMatches);

      if (hostMatches !== null) {
        const host = hostMatches[this.HOST_INDEX];

        if (!host.includes('-')) {
          environment = 'prod';
        } else {
          const envSuffixMatches = host.match(appEnvironmentPattern);
          console.log('suffix matches = ', envSuffixMatches);
          const envSuffix = envSuffixMatches[this.APP_ENVIRONMENT_SUFFIX_INDEX];
          if (envSuffix === 'dev') {
            const appEnvironment = hostMatches[this.APP_ENVIRONMENT_INDEX];
            const appString = appEnvironment.match(appSubEnvironmentPattern);
            console.log('appString matches', appString);
            if (appString[1] !== undefined) {
              environment = appString[2];
            }
          } else {
            environment = envSuffix;
          }
        }
      } else {
        environment = 'qa';
      }
    } else {
      environment = 'qa';
    }
    console.log('environment = ', environment);
    return environment;
  }
}
export default EnvironmentResolver;
