import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

export interface CenterViewProps {
  style?: ViewStyle;
}
export const CenterViewContainer = (props: React.PropsWithChildren<CenterViewProps>) => {
  return <View style={[props.style, styles.container]}>{props.children}</View>;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
