import React from 'react';
import {CenterViewContainer} from '../../../welcomeCommon/CenterViewContainer';
import {SingleTenantTitleAndCheckinButton} from '../leftView/SingleTenantTitleAndCheckinButton';

export const SingleTenantCenterViewWithTitle = () => {
  return (
    <CenterViewContainer>
      <SingleTenantTitleAndCheckinButton />
    </CenterViewContainer>
  );
};
