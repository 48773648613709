import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {MultiTenantScreen} from '../../features/multiTenantWelcome/MultiTenantScreen';
import {KioskNavigator} from './KioskNavigator';
import {useActivatedContext} from '../context/IActivatedContext';
import {log} from '../utils/Log';

export type KioskNavigatorParamList = {
  welcome: undefined;
  multiTenant: undefined;
  kioskNavigator: {tenantId: string};
};

declare global {
  namespace ReactNavigation {
    interface RootParamList extends KioskNavigatorParamList {}
  }
}

const Stack = createStackNavigator<KioskNavigatorParamList>();
export const MultiTenantKioskNavigator = () => {
  const {companyData} = useActivatedContext().companyDataProvider;
  log('rendering multiTenantNavigator', companyData.company.name);
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="multiTenant"
        component={MultiTenantScreen}
        options={{
          headerShown: false,
          animationEnabled: true,
        }}
      />
      <Stack.Screen name="kioskNavigator" component={KioskNavigator} />
    </Stack.Navigator>
  );
};
