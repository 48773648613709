import {StyleSheet, Text, View} from 'react-native';
import React from 'react';
import {ITenantData} from '../../common/api/models/ITenantData';

interface IQrCodeOrLabelProps {
  companyData: ITenantData;
}

export const QrCodeOrLabel = (props: IQrCodeOrLabelProps) => {
  const textColor = props.companyData.kioskParameters.titleColor;
  return (
    <View style={styles.orView}>
      <Text
        testID={'orLabelId'}
        style={[
          styles.orLabel,
          {
            color: textColor,
          },
        ]}>
        Or
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  orView: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlignVertical: 'center',
  },
  orLabel: {
    textAlign: 'center',
    fontFamily: 'CircularStd-Book',
    fontWeight: 'normal',
    fontSize: 40,
  },
});
