import {useState} from 'react';
import {ITranslations} from '../api/models/ITranslations';

import LocalizedStrings from 'react-localization';
import arabic from './locale/ar';
import bulgarian from './locale/bg';
import czech from './locale/cs';
import danish from './locale/da';
import german from './locale/de';
import greek from './locale/el';
import english from './locale/en';
import englishUS from './locale/en-US';
import spanish from './locale/es';
import estonian from './locale/et';
import finnish from './locale/fi';
import french from './locale/fr';
import croatian from './locale/hr';
import hungarian from './locale/hu';
import italian from './locale/it';
import japanese from './locale/ja';
import korean from './locale/ko';
import lithuanian from './locale/lt';
import latvian from './locale/lv';
import dutch from './locale/nl';
import norwegian from './locale/no';
import polish from './locale/pl';
import portuguese from './locale/pt';
import romanian from './locale/ro';
import russian from './locale/ru';
import slovak from './locale/sk';
import slovenian from './locale/sl';
import serbian from './locale/sr';
import swedish from './locale/sv';
import thai from './locale/th';
import turkish from './locale/tr';
import ukrainian from './locale/uk';
import chinese from './locale/zh';
import chineseTW from './locale/zh-TW';
import {ITenantData} from '../api/models/ITenantData';

export const strings = new LocalizedStrings({
  ar: arabic,
  bg: bulgarian,
  cs: czech,
  da: danish,
  de: german,
  el: greek,
  en: english,
  enUS: englishUS,
  es: spanish,
  et: estonian,
  fi: finnish,
  fr: french,
  hr: croatian,
  hu: hungarian,
  it: italian,
  ja: japanese,
  ko: korean,
  lt: lithuanian,
  lv: latvian,
  nl: dutch,
  no: norwegian,
  pl: polish,
  pt: portuguese,
  ro: romanian,
  ru: russian,
  sk: slovak,
  sl: slovenian,
  sr: serbian,
  sv: swedish,
  th: thai,
  tr: turkish,
  uk: ukrainian,
  zh: chinese,
  zhTW: chineseTW,
});

export interface ITranslationsProviderProps {
  tenantData: ITenantData;
}

export interface ITranslationsProvider {
  customTranslations: ITranslations;
  currentLanguage: string;

  getTextForKey(translationKey: string, ...optionalReplacementValues: string[]): string;

  setLanguage(languageCode: string): void;
}

export const useTranslationsProvider = (props: ITranslationsProviderProps): ITranslationsProvider => {
  const customTranslations = props.tenantData.translations;
  const [currentLanguage, setCurrentLanguage] = useState<string>();

  const getTextForKey = (translationKey: string, ...optionalReplacementValues: string[]): string => {
    let translatedText: string = '';
    if (customTranslations && customTranslations.hasOwnProperty(currentLanguage)) {
      if (customTranslations[currentLanguage].hasOwnProperty(translationKey)) {
        translatedText = customTranslations[currentLanguage][translationKey];
      }
    }

    if (!translatedText) {
      translatedText = strings.getString(translationKey);
    }

    translatedText = replaceVariables(translatedText, optionalReplacementValues);
    return translatedText;
  };

  function replaceVariables(text: string, replacementValues: string[]): string {
    let replacedText: string = text.replace('{{Company_Name}}', props.tenantData.company.name);
    for (let i = 0; i < replacementValues?.length; i++) {
      replacedText = replacedText.replace(`{${i}}`, replacementValues[i]);
    }
    return replacedText;
  }

  function setLanguage(languageCode: string): void {
    strings.setLanguage(languageCode);
    setCurrentLanguage(languageCode);
  }

  return {
    customTranslations,
    getTextForKey,
    setLanguage,
    currentLanguage,
  };
};
