import {useStorage} from '../storage/UseStorage';
import {useEffect, useState} from 'react';
import {companyKey} from '../storage/StorageKeys';
import {IAuthenticatedApi} from '../api/UseAuthenticatedApi';
import {ITenantData} from '../api/models/ITenantData';
import {useParamsLoader} from '../../features/loading/UseParamsLoader';
import {IPubnubConnector} from '../pubnub/UsePubnubConnector';
import {IKioskInstance} from '@proxyclick/data-model/src/all-models';

interface ICompanyDataProviderProps {
  authenticatedApi: IAuthenticatedApi;
  pubnubConnector: IPubnubConnector;
}

export interface ICompanyDataProvider {
  companyData: ITenantData;
  initialize(device: IKioskInstance): Promise<ITenantData>;
}

export const useCompany = (props: ICompanyDataProviderProps | null): ICompanyDataProvider => {
  const {storeItem, getItem} = useStorage();
  const {loadDataForTenant} = useParamsLoader({
    authenticatedApi: props.authenticatedApi,
  });
  const [companyData, setCompanyData] = useState<ITenantData | null>(null);

  const {
    kioskParametersUpdate,
    companyParametersUpdate,
    employeeParametersUpdate,
    translationsUpdate,
    languagesUpdate,
    companyUpdate,
  } = props.pubnubConnector;

  async function initialize(device: IKioskInstance): Promise<ITenantData> {
    const companyString: string = await getItem(companyKey);
    let theCompanyData: ITenantData = JSON.parse(companyString);
    if (theCompanyData) {
      setCompanyData(theCompanyData);
    } else {
      const params = await loadDataForTenant(device.company.id);
      theCompanyData = {
        company: device.company,
        ...params,
      };
      await storeItem(companyKey, JSON.stringify(theCompanyData));
      setCompanyData(theCompanyData);
    }
    return theCompanyData;
  }

  useEffect(() => {
    if (kioskParametersUpdate && companyData && kioskParametersUpdate.companyId === companyData.company.id) {
      console.log('useCompany: got kioskParametersUpdated event: ', companyData, kioskParametersUpdate.companyId);
      companyData.kioskParameters = kioskParametersUpdate.kioskParameters;
    }
  }, [kioskParametersUpdate]);

  useEffect(() => {
    if (companyParametersUpdate && companyData && companyParametersUpdate.companyId === companyData.company.id) {
      companyData.companyParameters = companyParametersUpdate.companyParameters;
    }
  }, [companyParametersUpdate]);

  useEffect(() => {
    if (employeeParametersUpdate && companyData && employeeParametersUpdate.companyId === companyData.company.id) {
      companyData.employeeParameters = employeeParametersUpdate.employeeParameters;
    }
  }, [employeeParametersUpdate]);

  useEffect(() => {
    if (translationsUpdate && companyData && translationsUpdate.companyId === companyData.company.id) {
      companyData.translations = translationsUpdate.translations;
    }
  }, [translationsUpdate]);

  useEffect(() => {
    if (languagesUpdate && companyData && languagesUpdate.companyId === companyData.company.id) {
      companyData.languages = languagesUpdate.languages;
    }
  }, [languagesUpdate]);

  useEffect(() => {
    if (companyUpdate && companyData && companyUpdate.companyId === companyData.company.id) {
      companyData.company = companyUpdate.company;
    }
  }, [companyUpdate]);

  return {
    companyData,
    initialize,
  };
};
