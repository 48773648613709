import {useStorage} from '../storage/UseStorage';
import {useEffect, useState} from 'react';
import {deviceKey} from '../storage/StorageKeys';
import {IAuthenticatedApi} from '../api/UseAuthenticatedApi';
import {IKioskInstance} from '@proxyclick/data-model/src/all-models';
import {log} from '../utils/Log';

interface IDeviceProviderProps {
  authenticatedApi: IAuthenticatedApi;
}

export interface IDeviceProvider {
  device: IKioskInstance | null;

  setDeviceId(deviceId: string): void;

  setCompanyId(companyId: string): void;

  activated: boolean;
  initialize(): Promise<IKioskInstance>;
}

export const useDevice = (props: IDeviceProviderProps | null): IDeviceProvider => {
  const {storeItem, getItem} = useStorage();
  const [deviceId, setDeviceId] = useState<string | null>(null);
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [device, setDevice] = useState<IKioskInstance | null>(null);
  const {isAuthenticated, fetchDevice} = props.authenticatedApi;
  const [activated, setActivated] = useState<boolean>(false);

  useEffect(() => {
    if (companyId && deviceId) {
      setActivated(true);
    }
  }, [companyId, deviceId]);

  async function initialize(): Promise<IKioskInstance> {
    log('initializing device provider');
    const deviceString: string = await getItem(deviceKey);
    let theDevice: IKioskInstance = JSON.parse(deviceString);
    if (theDevice) {
      log('stored device found', theDevice);
      setDevice(theDevice);
    } else {
      log('No stored device found');
      if (isAuthenticated && companyId && deviceId) {
        log('Fetching device...');
        theDevice = await fetchDevice(companyId, deviceId);
        await storeItem(deviceKey, JSON.stringify(theDevice));
        setDevice(theDevice);
        log('Got device ', theDevice);
      }
    }
    return theDevice;
  }

  return {
    device,
    setDeviceId,
    setCompanyId,
    activated,
    initialize,
  };
};
