import {usePubnubConnector} from '../pubnub/UsePubnubConnector';
import {IActivatedContext} from './IActivatedContext';
import {useAuthenticatedApi} from '../api/UseAuthenticatedApi';
import {useAuthenticator} from '../authenticator/UseAuthenticator';
import {useUnauthenticatedApi} from '../api/UseUnauthenticatedApi';
import {useFakeDevice} from '../configuration/UseFakeDevice';
import {useDashboardEnvironment} from '../configuration/UseDashboardEnvironment';
import {useEffect, useState} from 'react';
import {IKioskInstance} from '@proxyclick/data-model/src/all-models';
import {ITenantData} from '../api/models/ITenantData';
import {useFakeCompany} from '../configuration/UseFakeCompany';
import {useFakeTenants} from '../configuration/UseFakeTenants';

export interface IDashboardActivatedContextProps {
  token: string;
  companyId: string;
  screen: string;
}

export const DashboardActivatedContext = (props: IDashboardActivatedContextProps): IActivatedContext => {
  const [initialized, setInitialized] = useState(false);
  const authenticator = useAuthenticator({token: props.token});

  const environment = useDashboardEnvironment();

  const authenticatedApi = useAuthenticatedApi({
    authenticator,
    environment,
  });

  const {isAuthenticated} = authenticatedApi;

  const unauthenticatedApi = useUnauthenticatedApi({
    environment,
  });

  const pubnubConnector = usePubnubConnector({authenticatedApi});

  const deviceHook = useFakeDevice({
    companyId: props.companyId,
    pubnubConnector,
    authenticatedApi: authenticatedApi,
  });

  const companyHook = useFakeCompany({
    authenticatedApi,
    pubnubConnector,
  });

  const tenants = useFakeTenants({
    authenticatedApi: authenticatedApi,
    pubnubConnector,
  });

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        await initialize();
      }
    })();
  }, [isAuthenticated]);

  async function initialize(): Promise<void> {
    const device: IKioskInstance = await deviceHook.initialize();
    const companyData: ITenantData = await companyHook.initialize(device);
    await tenants.initialize(device, companyData);
    setInitialized(true);
  }

  return {
    authenticator,
    environment,
    authenticatedApi,
    unauthenticatedApi,
    deviceProvider: deviceHook,
    companyDataProvider: companyHook,
    tenants,
    pubnubConnector,
    initialize,
    initialized,
  };
};
