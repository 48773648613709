export default {
  common_button_back: '回上頁',
  common_button_cancel: '取消',
  common_button_next: '下一頁',
  common_button_no: '否',
  common_button_ok: '可以',
  common_button_reset: '重設',
  common_button_skip: '略過',
  common_button_yes: '是',
  common_deactivated_message: 'Kiosk已在另一台iPad上啟用，因此不再支援iPad。 請再次登錄。 謝謝！',
  common_deactivated_title: 'Kiosk停用了這台iPad',
  common_enterEmail_email: '請輸入您的公司電子郵件',
  common_enterEmail_title: '你的電子郵件信箱',
  common_error_message_cameraNotAllowed: '需要允許存取攝影機才能使用此功能。',
  common_error_message_ForbiddenException: '抱歉，您似乎沒有足夠的權限執行此操作',
  common_error_message_incorrectEmail: '請輸入正確的電子郵件地址',
  common_error_message_invalidCredentials: '糟糕！ 帳号或密碼不正確。 請再試一遍',
  common_error_message_invalidMobile: '請輸入正確的手機號碼',
  common_error_message_missingField: '必填',
  common_error_message_noInternetConnection: '網際網路連線已中斷。 請再試一遍。',
  common_error_message_unauthorizedAccess: '未授權存取',
  common_error_message_webServiceWrongConnection: '數據傳輸期間發生錯誤。 請再試一遍',
  common_error_title: '錯誤',
  common_expired_message:
    '您的試用期或信用卡已到期。 請升級重新啟用該帳戶。 如有任何疑問，請聯繫sales@proxyclick.com。 謝謝！',
  common_expired_title: '帳戶過期',
  common_onboarding_login_button: '登入',
  common_onboarding_message1: 'Proxyclick可幫助您完全按照自己的方式歡迎訪客。',
  common_onboarding_message2: '為您的來訪列印訪客證，並向訪客詢問適用於他們的問題。',
  common_onboarding_message3: '受訪者在客人到達時會收到通知，並可以立即回复。',
  common_onboarding_message4: '保護您的設備，並通過緊急列表，觀察列表，NDAs，隱私規則確保符合規定...',
  common_onboarding_message5: 'Proxyclick 會努力工作，因此您可以微笑歡迎您的訪客。',
  common_placeholder_optional: '可選擇的',
  common_setup_activationCode_activate_button: '啟用',
  common_setup_activationCode_code: '請輸入你的啟動密碼',
  common_setup_activationCode_code_placeholder: '例如: YSSFG-CVWVR-JFRWX-HSNMK',
  common_setup_activationCode_error_invalidCode: '無效的啟用碼',
  common_setup_activationCode_footer: '啟用碼對於單一登入管理員或當地人員非常方便(了解更多)',
  common_setup_activationCode_title: '使用代碼啟用Kiosk',
  common_setup_device_footer: '要建立新的互動式平台，請連接到Proxyclick儀表板',
  common_setup_device_locked_content:
    '互動式平台已在另一台iPad使用。在您手中的iPad上進行設置後，另一台將停用它。 您確定要在此iPad上設置互動式平台嗎？',
  common_setup_device_locked_continue_button: '設定這個互動式平台',
  common_setup_device_locked_title: '互動式平台已在使用中',
  common_setup_device_multiTenants: '多公司',
  common_setup_device_title: '選擇你的互動式平台',
  common_setup_loading_footer: '要自定義您的互動式平台，請連接到Proxyclick儀表板',
  common_setup_loading_message: '設置中...',
  common_setup_loading_title: '您的互動式平台正在設置中...',
  common_setup_location_footer: '要建立新地點，請連接到Proxyclick儀表板',
  common_setup_location_kiosk: '互動式平台',
  common_setup_location_kiosks: '互動式平台',
  common_setup_location_title: '選擇你的地點',
  common_setup_login_activationCode_button: '使用唯一代碼啟用？',
  common_setup_login_email: '電子郵件',
  common_setup_login_forgotPassword_button: '忘記密碼了嗎？',
  common_setup_login_password: '密碼',
  common_setup_login_title: '登入你的Proxyclick 帳号',
  common_setup_ready_header: '準備將此iPad設置為您的{0}互動式平台？',
  common_setup_ready_message: '只需一分鐘即可配置，您的互動式平台將啟動並執行',
  common_setup_ready_setup_button: '設定',
  common_setup_ready_title: '您好{0}',
  common_signup_company: '公司',
  common_signup_email: '電子郵件',
  common_signup_error_name: '請輸入姓氏和名字',
  common_signup_loading_message1:
    '您可以自行定義您的Kiosk連接到Proxyclick儀表板。 我們剛剛向您發送了一封包含所有詳細信息的電子郵件。',
  common_signup_loading_message2: '您可以從網站上設置印表機以在簽到時列印識別證。',
  common_signup_loading_message3: '您還可以辦理簽到時啟用訊息通知。',
  common_signup_loading_message4: '並使用自行定義自己的顏色和歡迎圖片',
  common_signup_loading_title: '設置您的帳戶',
  common_signup_name: '姓名',
  common_signup_name_placeholder: '姓 名',
  common_signup_password: '密碼',
  common_signup_title: '歡迎使用Proxyclick',
  common_signup_tryFree_button: '送出',
  common_timeout_content: '輕觸即可中止',
  common_timeout_title: '返回主畫面',
  common_tooltip_disable_button: '停用所有提示',
  common_tooltip_gotIt_button: '收到',
  dm_delivery_confirmation_knownRecipient: '{{Host_Name}}已知悉您已交付包裹',
  dm_delivery_confirmation_title: '謝謝！',
  dm_delivery_confirmation_unknownRecipient: '已發送通知給{{Company_Name}}，包裹已送達',
  dm_delivery_recipient_name: '這個包裏是誰的？',
  dm_delivery_recipient_unknown_button: '我不知道',
  em_multiTenant_alreadyRegistered_button: '如果您是員工或已收到二維代碼邀請，請點擊此處辦理登入',
  em_multiTenant_notRegistered_button: '點按此處並手動簽名',
  vm_checkin_approval_confirmation_unregistered_message:
    '我們目前無法完成您的登入手續，請聯繫您的主辦人或至櫃台尋求協助',
  vm_checkin_approval_confirmation_unregistered_title: '歡迎 {{訪客_名}} {{訪客_姓}}',
  vm_checkin_approval_notapproved_message: '我們目前無法完成您的登入手續，請聯繫您的主辦人或至櫃台尋求協助',
  vm_checkin_approval_notapproved_title: 'Dear {{Visitor_FirstName}} {{Visitor_LastName}}',
  vm_checkin_confirmation_header_withoutPrint: '您已註冊！',
  vm_checkin_confirmation_header_withPrint: '你的識別證正在列印',
  vm_checkin_confirmation_message: '請坐，{{Host_FirstName}}將會在您身邊',
  vm_checkin_confirmation_takeBackID: '請不要忘記您的身份證',
  vm_checkin_confirmation_title: '謝謝!',
  vm_checkin_details_companyName: '公司',
  vm_checkin_details_email: '電子郵件',
  vm_checkin_details_firstname: '姓氏',
  vm_checkin_details_lastname: '名子',
  vm_checkin_details_title: '請輸入您的詳細資訊',
  vm_checkin_error_message_unableToCheckIn: '完成簽到時發生錯誤，請聯繫接待處',
  vm_checkin_flow_company: '公司',
  vm_checkin_flow_duration: '參訪時間',
  vm_checkin_flow_email: '電子郵件',
  vm_checkin_flow_error_message_imageNotAvailable: '糟糕，圖片目前不可使用（Internet連接問題）',
  vm_checkin_flow_licensePlate: '授權證',
  vm_checkin_flow_mobile: '請輸入您的手機號碼',
  vm_checkin_flow_mobile_info: '當您在工廠發生緊急情況時，它將用於與您取得聯繫',
  vm_checkin_flow_tooltip_content:
    '向您的訪客詢問您需要什麼來改善他們的體驗或使您的登記更加安全。 您可以根據訪問者的答案更改問題的流程。 您看到的問題只是範例。',
  vm_checkin_flow_tooltip_title: '很好的問題',
  vm_checkin_host_enterFullName: '請輸入您的姓名',
  vm_checkin_host_name: '請問您找誰?',
  vm_checkin_host_notListed_block: '如果您不知道他的名子或在列表中找不到他/她，請與接待員討論',
  vm_checkin_host_notListed_continue: '不在清單中嗎？',
  vm_checkin_host_tooltip_content: '在此處輸入您的姓名，我們會向您發送訊息，您的員工在訪客抵達時會收到通知。',
  vm_checkin_host_tooltip_title: '訪客抵達通知',
  vm_checkin_host_unknown_button: '我不知道',
  vm_checkin_idScan_aila_footer: '當您使用推薦的硬體({0}）時，將跳過此畫面',
  vm_checkin_idScan_aila_header: '請掃描您的身份證',
  vm_checkin_idScan_aila_learnMore: '學到更多',
  vm_checkin_idScan_aila_scan_button: '掃描身份證',
  vm_checkin_idScan_back_picture_header_aila: '將您的身份證放在支架上，使背面朝向相機。',
  vm_checkin_idScan_back_picture_header_noAila: '將您的身份證放在支架上，使背面朝向相機。',
  vm_checkin_idScan_back_picture_take_button: '掃描背面',
  vm_checkin_idScan_cancel_message: '登記手續將被取消，您將返回主畫面',
  vm_checkin_idScan_cancel_title: '警告',
  vm_checkin_idScan_disclaimer_start_button: '讓我們開始吧',
  vm_checkin_idScan_disclaimer_text:
    '作為我們安全政策的一部分，我們需要使用您的身份證來驗證您的身份。\n\n我們重視您的隱私，因此我們不會存儲您的身份證圖片。 如有任何疑問，接待人員將為您提供幫助。',
  vm_checkin_idScan_editName_title: '請輸入姓氏和名字',
  vm_checkin_idScan_front_picture_header_aila: '將您的身份證放在支架上，使正面朝向相機。',
  vm_checkin_idScan_front_picture_header_noAila: '放置您的身份證，使正面朝著相機。',
  vm_checkin_idScan_front_picture_take_button: '掃描正面',
  vm_checkin_idScan_match_check: '比較兩張圖片',
  vm_checkin_idScan_match_confirm_button: '確認',
  vm_checkin_idScan_match_error_default_header: '抱歉，出了一些問題。',
  vm_checkin_idScan_match_error_default_message: '向接待人員尋求幫助或重試。',
  vm_checkin_idScan_match_error_idExpired_header: '抱歉，您的身份登似乎已過期。',
  vm_checkin_idScan_match_error_idExpired_message: '向接待人員尋求幫助或重試。',
  vm_checkin_idScan_match_error_idNoFace_header: '抱歉，我們無法在您的身份證上識別臉部照片。',
  vm_checkin_idScan_match_error_idNoFace_message: '向接待人員尋求幫助或重試。',
  vm_checkin_idScan_match_error_pictureNoFace_header: '抱歉，我們無法在您的身份證上識別臉部照片。',
  vm_checkin_idScan_match_error_pictureNoFace_message: '向接待人員尋求幫助或重試。',
  vm_checkin_idScan_region_0: '美國',
  vm_checkin_idScan_region_1: '加拿大',
  vm_checkin_idScan_region_2: '南韓',
  vm_checkin_idScan_region_3: '歐洲',
  vm_checkin_idScan_region_4: '澳洲',
  vm_checkin_idScan_region_5: '亞洲',
  vm_checkin_idScan_region_6: '一般文件',
  vm_checkin_idScan_region_7: '非洲',
  vm_checkin_idScan_region_header: '您的身份證來自哪個地區？',
  vm_checkin_instructions_agreeTerms_button: '同意',
  vm_checkin_instructions_clearSignature_button: '明確',
  vm_checkin_instructions_sendCopy_button: '通過電子郵件發送給我',
  vm_checkin_instructions_sendCopyTo_button: '副本將發送到{0}（更改）',
  vm_checkin_instructions_sign: '請用手指在這裡簽名',
  vm_checkin_instructions_tooltip_content:
    '通過簡單的點擊或簽名，讓您的客人接受您的訪問規則。 您可以根據訪問者登錄的類型來調整您的條款。在Proxyclick儀表板中自定義此功能以及許多其他功能。',
  vm_checkin_instructions_tooltip_title: '簽名並蓋章',
  vm_checkin_method_name: '你好，請問你叫什麼名字？',
  vm_checkin_method_name_enterFullName: '請輸入您的全名',
  vm_checkin_method_name_invitationQuestion: '您是否收到帶有二維碼的邀請?',
  vm_checkin_method_name_notRegistered_block: '不在清單中嗎？ 請向接待處查詢',
  vm_checkin_method_name_notRegistered_continue: '不在清單中嗎？',
  vm_checkin_method_tooltip_content:
    '以“ Justin Diaz”身份登錄，以查看如何識別回訪者或預先註冊的訪客。\n\n或輸入隨機名稱以查看臨時訪客的處理方式。',
  vm_checkin_method_tooltip_title: '訪客識別',
  vm_checkin_picture_auto_countDown: '我們即將拍照，請稍候...',
  vm_checkin_picture_auto_lookCamera: '請看向相機，我們現在會為您拍照。',
  vm_checkin_picture_auto_pictureTaken: '拍照完畢',
  vm_checkin_picture_auto_stayStill: '請不要移動',
  vm_checkin_picture_take_button: '拍新照片',
  vm_checkin_picture_title: '讓我們拍張你的照片吧！',
  vm_checkin_picture_tooltip_content:
    '您可以根據需要設定互動式平台，並輕鬆添加或刪除功能，例如拍攝訪客照片或列印識別證。',
  vm_checkin_picture_tooltip_title: '可選擇功能',
  vm_checkin_qrcode_no_checkin_allowed: '登入不被允許，請聯繫櫃台接待處尋求協助',
  vm_checkin_returning_email: '您好，您的電子郵件地址是什麼？',
  vm_checkin_returning_email_notRegistered: '找不到嗎？請聯繫櫃台查詢。',
  vm_checkin_saveInfo_email: '儲存我的信息以加快下一次簽到的速度',
  vm_checkin_saveInfo_email_placeholder: '輸入您的電子郵件地址',
  vm_checkin_saveInfo_title: '輸入你的電子郵箱',
  vm_checkin_summary_agreementAlreadySigned: '協議已簽署',
  vm_checkin_summary_checkin_button: '立即簽到',
  vm_checkin_summary_countDown: '您的到訪登記手續即將完成，請稍候...',
  vm_checkin_summary_editDetails_button: '編輯詳細資料',
  vm_checkin_summary_groupStats: '已經有{1}個人中的{0}個人在這裡',
  vm_checkin_summary_meetingTime: '在{0}開會',
  vm_checkin_summary_reviewDetails_button: '查看我的訪問詳細信息',
  vm_checkin_summary_sendPass_button: '向我發送通行證，以供日後訪問我的智慧型手機',
  vm_checkin_summary_sendPassTo_button: '永久通行證將發送至{0}（更改）',
  vm_checkin_summary_takeNewPicture_button: '拍新照片',
  vm_checkin_summary_title: '您在{{Company_Name}}的訪問',
  vm_checkin_summary_visiting: '正在訪問{0}',
  vm_checkin_video_duration: '期間',
  vm_checkin_video_subtitle: '請觀看完整的影片後並繼續辦理您的登入',
  vm_checkin_welcome_firstTime: '歡迎{{Visitor_FirstName}}！',
  vm_checkin_welcome_lastVisit_date: '您上次來訪的時間是{0}',
  vm_checkin_welcome_lastVisit_today: '你上次來訪比今天早',
  vm_checkin_welcome_lastVisit_yesterday: '您的上次訪問是昨天',
  vm_checkin_welcome_returning: '歡迎回到{{Visitor_FirstName}}！',
  vm_checkout_confirmation_header: '再見 {{Visitor_FirstName}}!',
  vm_checkout_confirmation_message: '您已完成登出。 希望您訪問愉快。',
  vm_checkout_confirmation_title: '感謝您的光臨！',
  vm_checkout_method_name: '請輸入您的姓名以便結束訪問',
  vm_checkout_method_name_notFound: '未找到',
  vm_checkout_method_title: '結束訪問',
  vm_identification_id_button: '掃描您的身份證',
  vm_identification_qrCode_button: '掃描您的邀請碼/通行證',
  vm_infopack_confirmation: '謝謝，我們已將電子郵件發送到{0}。',
  vm_infopack_email: '請輸入您的電子郵件，以便我們向您發送有關Proxyclick的訊息',
  vm_infopack_likeCheckIn: '喜歡這個簽到嗎？ 點按此處了解更多',
  vm_infopack_likeProcess: '喜歡這個過程嗎？ 點按此處了解更多',
  vm_infopack_send_button: '發送訊息',
  vm_multiTenant_title: '歡迎光臨，您正在訪問哪家公司？',
  vm_product_footer_learnMore: '學到更多',
  vm_product_footer_noPartner: '您正在使用Proxyclick互動式平台',
  vm_product_footer_partner: '您正在使用{0}帶給您的Proxyclick互動式平台',
  vm_qrCode_help: '保持掃瞄物距離相機約10-15公分',
  vm_qrCode_not_recognized: '代碼無法識別',
  vm_qrCode_title: '掃描您的識別登或識別碼',
  vm_signup_defaultInstruction_content:
    '<img class="instruction-icon" src="http://static.proxyclick.com/_/app/img/safetyIcons/terms/icon-nopic.png" width="50"/>訪客說明和保密協議</h1><p>歡迎！ 我們很高興邀請您成為{{Company_Name}} 的來賓。</p> <h2>健康和安全說明</h2> <p>在您的整個來訪過程中，您的人身安全是我們關心的問題。 因此，我們要求您遵守以下規定：</p><ul><li><b>進入場所前</b>僅在有{{Company_Name}}員工的陪同的情況下才允許進入</li><li>訪客<b>識別證</b>必須全程佩戴，並在離開時歸還接待處</li><li>在<b>緊急情況</b>下，所有訪客必須立即通過最近的安全出口離開場所，並依通知到指定的集合點。</li><li>訪客<b>嚴禁在本場所或周圍設施內攝影</b>（照片和影片），</li><li>在建築物內<b>嚴禁吸煙。</b></li></ul><h2>保密協議</h2><p>在您訪問 {{Company_Name}}的期間，您可能會了解/或向您披露了通常不為公眾所知的專有或機密信息（包括但不限於與技術，過程，設備，圖搞和規格有關的訊息） 統稱為&#34;機密訊息&#34;）。</p> <p>考量<p>到您允許訪問{{Company_Name}}以及您訪問期間的禮貌，雙方皆同意你不會:</p></p> （a）披露或在其他情況下以及在隨後的訪問中披露的任何機密信息； 或</p><p>（b）使用或協助他人以任何方式使用或進一步開發任何機密信息。</p><p>（c）使用鏡頭或影像技術披露機密信息。</p> <p>在此設備簽名，即表示您確認並了解所發佈的《健康與安全說明和保密協議》。</p><p><p>',
  vm_signup_defaultInstruction_name:
    '翻譯密鑰命名規則以"common"，表示模組之間的共享元素或不是模組專有的內容（例如：“ yes”，“ no”，“ none”）\n以“ {moduleName}”開頭，表示僅限於特定模組的元素（例如：“ sd”）使用此元素通過以下標記來描述此元素的更精確上下文標記：{pageName}，{divName}，表單，標題，按鈕，欄位，提示，錯誤,等使用camelCase命名（ex：）',
  vm_signup_defaultInstruction_title: '請閱讀下文，並在下面簽名',
  vm_vaccine_certification_accessDenied_button: 'Okay',
  vm_vaccine_certification_accessDenied_message:
    "Sorry you don't meet the vaccination requirements to enter the premisses",
  vm_vaccine_certification_accessDenied_title: 'Access has been denied',
  vm_vaccine_certification_declare_question_title:
    'Dear visitor, please declare whether you have a valid COVID vaccine certificate or a COVID test with a negative result',
  vm_vaccine_certification_intro_question_title: 'Dear visitor, do you have an EU COVID Vaccine Certificate?',
  vm_vaccine_certification_scanner_donthaveaqrcode: "I don't have a QR code",
  vm_vaccine_certification_scanner_subtitle:
    'Make sure that your QR code is enlarged and the brightness of your mobile phone is on maximum.',
  vm_vaccine_certification_scanner_title: 'Please present the QR code of your EU COVID certificate to the camera',
  vm_welcome_changeLanguage_button: '變更語言',
  vm_welcome_checkin_button: '登錄',
  vm_welcome_checkout_button: '辦理離開',
  vm_welcome_delivery_button: '交貨',
  vm_welcome_hello_line1: '你好，',
  vm_welcome_hello_line2: '來 {{Company_Name}}',
  vm_welcome_qrCode_checkedIn: '歡迎{0}小姐/先生，離開時請謹記辦理手續',
  vm_welcome_qrCode_checkedOut: '下次再見',
  vm_welcome_qrCode_notRecognized: '號碼無法辨認。請於三秒內再次輸入...',
  vm_welcome_qrCode_scan: '請於到訪及離開時掃描邀請函內的二維碼',
  vm_welcome_qrCode_show: '員工: 使用Proovr 掃描此二維碼以登入或登出',
  vm_welcome_qrCode_wrongCompany: '您的二維碼對此位置無效',
  vm_welcome_returning_button: '以前來過這裡嗎？',
  vm_welcome_title: '歡迎來到 {{Company_Name}}',
  vm_welcome_tooltip_content:
    '要個性化你的互動式平台，請登錄Proxyclick訊息中心。 我們只是向您發送了一封包含說明的電子郵件。 在訊息中心中，您可以上傳企業商標，背景圖片並選擇顏色，以便您的互動式平台能夠反映您的品牌形象。',
  vm_welcome_tooltip_title: '自已做',
  vm_welcome_touchless_employee_qrCode_show: '使用Proovr 掃描此二維碼以作為員工登入',
  vm_welcome_touchless_qrCode_show: '未註冊訪客: 掃描此二維碼以進行非觸碰式登入',
  vm_welcome_touchless_unexpected_and_employee_qrCode_show: '使用Proovr (員工) 或您的手機(訪客) 掃描此二維碼以辦理登入',
  vm_welcome_touchless_unexpected_qrCode_show: '請使用手機掃描此二維碼以訪問者身份辦理登入',
  common_or: '或',
};
