import {StyleSheet} from 'react-native';

export const multiTenantCellStyles = StyleSheet.create({
  cellContainer: {
    backgroundColor: 'white',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: 230,
    height: 150,
    marginHorizontal: 10,
    marginBottom: 20,
    borderRadius: 10,
  },
  imageThumbnail: {
    flex: 1,
    width: 230,
    resizeMode: 'contain',
    aspectRatio: 230 / 150,
  },
});
