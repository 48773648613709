import React from 'react';
import {useTenantContext} from '../../../../common/context/TenantContext';
import {WelcomeTitle} from '../../../welcomeCommon/WelcomeTitle';

export interface SingleTenantWelcomeTitleProps {
  multiLine: boolean;
}

export const SingleTenantWelcomeTitle = (props: SingleTenantWelcomeTitleProps) => {
  const {tenantData, translationsProvider} = useTenantContext();
  return <WelcomeTitle tenantData={tenantData} translations={translationsProvider} multiLine={props.multiLine} />;
};
