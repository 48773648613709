import React, {useEffect, useState} from 'react';
import {ImageBackground, StyleSheet, View} from 'react-native';
import {useTenantContext} from '../../common/context/TenantContext';

export interface ScreenWithBackgroundProps {}

export const ScreenWithBackground = (props: React.PropsWithChildren<ScreenWithBackgroundProps>) => {
  const [isLoading, setIsLoading] = useState(true);
  const [backgroundImageEnabled, setBackgroundImageEnabled] = useState(false);
  const {kioskParameters} = useTenantContext().tenantData;

  useEffect(() => {
    console.log('loading background ...');
    if (kioskParameters) {
      setBackgroundImageEnabled(kioskParameters?.backgroundImageUrl && kioskParameters?.backgroundImageUrl.length > 0);
      setIsLoading(false);
      console.log('Background loaded');
    } else {
      console.log('Warning: no kiosk parameters to load background');
      setIsLoading(true);
    }
  }, [kioskParameters]);

  return (
    <View style={styles.container} testID={'homeScreenId'}>
      {!isLoading && backgroundImageEnabled && (
        <ImageBackground
          testID={'imageBackgroundId'}
          resizeMode="cover"
          style={styles.container}
          source={{uri: kioskParameters.backgroundImageUrl}}>
          {props.children}
        </ImageBackground>
      )}
      {!isLoading && !backgroundImageEnabled && (
        <View style={[styles.backgroundView, {backgroundColor: kioskParameters.primaryColor}]}>{props.children}</View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backgroundView: {
    flex: 1,
  },
});
