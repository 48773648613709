import React from 'react';
import {CenterViewContainer} from '../../../welcomeCommon/CenterViewContainer';
import {MultiTenantCheckinQRCode} from '../qr/MultiTenantCheckinQRCode';
import {MultiTenantQRCodeReader} from '../qr/MultiTenantQRCodeReader';
import {useActivatedContext} from '../../../../common/context/IActivatedContext';
import {QrCodeOrLabel} from '../../../welcomeCommon/QrCodeOrLabel';

export const MultiTenantCenterViewWithQRCodeAndReader = () => {
  const {companyData} = useActivatedContext().companyDataProvider;
  return (
    <CenterViewContainer>
      <MultiTenantCheckinQRCode />
      <QrCodeOrLabel companyData={companyData} />
      <MultiTenantQRCodeReader />
    </CenterViewContainer>
  );
};
