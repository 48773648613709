import LabelButton from '../../../singleTenantWelcome/components/bottomBar/LabelButton';
import {NotRegisteredIcon} from '../../../../assets/icons/Icons';
import {StyleSheet, View} from 'react-native';
import React from 'react';
import {Sizes} from '@proxyclick/hello-react-native';
import {useActivatedContext} from '../../../../common/context/IActivatedContext';
import {useTranslationsProvider} from '../../../../common/translations/useTranslationsProvider';

const iconSize = 25;

export interface INotRegisteredButtonViewProps {
  onPress: () => void;
}

export const NotRegisteredButtonView = (props: INotRegisteredButtonViewProps) => {
  const {companyData} = useActivatedContext().companyDataProvider;
  const {getTextForKey} = useTranslationsProvider({tenantData: companyData});
  return (
    <View style={styles.notRegisteredButtonView}>
      <LabelButton
        textColor={companyData.kioskParameters.titleColor}
        testID={'notRegisteredYetButtonId'}
        iconLeft={
          <NotRegisteredIcon width={iconSize} height={iconSize} stroke={companyData.kioskParameters.titleColor} />
        }
        text={getTextForKey('em_multiTenant_notRegistered_button')}
        onPress={props.onPress}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  notRegisteredButtonView: {
    justifyContent: 'flex-end',
    paddingLeft: iconSize,
    height: Sizes.xxl,
    alignItems: 'center',
  },
});
