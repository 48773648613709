import QRCodeReader from '../../../welcomeCommon/QRCodeReader';
import React from 'react';
import {useActivatedContext} from '../../../../common/context/IActivatedContext';
import {useTranslationsProvider} from '../../../../common/translations/useTranslationsProvider';

export const MultiTenantQRCodeReader = () => {
  const {companyData} = useActivatedContext().companyDataProvider;
  const tenants = useActivatedContext().tenants;
  const translationsProvider = useTranslationsProvider({tenantData: companyData});
  return (
    <QRCodeReader
      identifier={'multiTenant_' + companyData.company.id}
      tenantData={companyData}
      translations={translationsProvider}
      isCompanyFound={(companyId: string) => {
        return tenants.tenants.get(companyId) !== undefined;
      }}
    />
  );
};
