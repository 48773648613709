import {useStorage} from '../../common/storage/UseStorage';
import {useState} from 'react';
import {IKioskParams} from '@proxyclick/data-model';
import {IAuthenticatedApi} from '../../common/api/UseAuthenticatedApi';
import {kioskParametersKey} from '../../common/storage/StorageKeys';
import {IApiError} from '../../common/api/UseApi';
import {log} from '../../common/utils/Log';

interface IKioskParametersLoaderProps {
  authenticatedApi: IAuthenticatedApi;
}

export interface IKioskParametersLoader {
  error: IApiError;

  load(tenantId: string): Promise<IKioskParams>;
}

export const useKioskParametersLoader = (props: IKioskParametersLoaderProps | null): IKioskParametersLoader => {
  const {storeItem} = useStorage();
  const [error, setError] = useState<IApiError | null>(null);
  const {fetchKioskParameters} = props.authenticatedApi;

  async function load(tenantId: string): Promise<IKioskParams> {
    try {
      log('loading kiosk parameters for ', tenantId);
      const theKioskParameters = await fetchKioskParameters(tenantId);
      await storeItem(kioskParametersKey + tenantId, JSON.stringify(theKioskParameters));
      log('COMPLETED loading kiosk parameters for ', tenantId);
      return theKioskParameters;
    } catch (loadError) {
      log('FAILED loading kiosk parameters for ', tenantId, 'Error: ', loadError);
      setError(loadError);
    }
  }

  return {
    load,
    error,
  };
};
