import React, {useEffect, useRef, useState} from 'react';
import {View, Animated, ActivityIndicator, Text, StyleSheet} from 'react-native';
import {QRArea, QRCheckMark} from '../../assets/images/Images';
import {useQRCodeDecoder, VisitStatus} from './UseQRCodeDecoder';
import {ITranslationsProvider} from '../../common/translations/useTranslationsProvider';
import {Sizes} from '@proxyclick/hello-react-native';
import {log} from '../../common/utils/Log';
import {QrSubText} from './QrSubText';
import {QR_AREA_MARGIN_SIZE_NATIVE, QR_READER_VIEWPORT_SIZE, styles as qrStyles} from './QRReaderStyles';
import {ITenantData} from '../../common/api/models/ITenantData';
import {QRCodeScanner} from './QRCodeScanner';
import {useIsFocused} from '@react-navigation/native';

export interface QRCodeReaderProps {
  identifier: string;
  tenantData: ITenantData;
  translations: ITranslationsProvider;
  isCompanyFound: (companyId: string) => boolean;
}

const errorColor = '#f24242';
const succeedColor = '#26db8e';
type scannerState = 'waiting' | 'processing' | 'succeeded' | 'failed';

const QRCodeReader = (props: QRCodeReaderProps) => {
  const {getTextForKey} = props.translations;
  const scaleAnim = useRef(new Animated.Value(0)).current;
  const qrCodeDecoder = useQRCodeDecoder({isCompanyFound: props.isCompanyFound, tenantData: props.tenantData});
  const [scannerStatus, setScannerStatus] = useState<scannerState>('waiting');
  const [error, setError] = useState<Error | null>(null);
  const [succeedMessage, setSucceedMessage] = useState<string | null>(null);
  const isFocused = useIsFocused();

  const handleScan = async (result: string | null) => {
    if (result) {
      setScannerStatus('processing');
      try {
        const processResult = await qrCodeDecoder.processQRCode(result);
        setScannerStatus('succeeded');
        const checkinMessage = `${getTextForKey(
          'vm_welcome_qrCode_checkedIn',
          processResult.matchedVisit?.visitor?.firstname + ' ' + processResult.matchedVisit?.visitor?.lastname,
        )}`;

        const checkoutMessage = `${getTextForKey(
          'vm_welcome_qrCode_checkedOut',
          processResult.matchedVisit?.visitor?.firstname + ' ' + processResult.matchedVisit?.visitor?.lastname,
        )}`;

        setSucceedMessage(
          processResult.visitStatus === VisitStatus.checked_in
            ? checkinMessage
            : processResult.visitStatus === VisitStatus.checked_out
            ? checkoutMessage
            : null,
        );
      } catch (e: any) {
        setError(e);
        setScannerStatus('failed');
        log('ProcessQrCode error', e);
      } finally {
        setTimeout(() => {
          setScannerStatus('waiting');
        }, 3000);
      }
    }
  };

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(scaleAnim, {
          useNativeDriver: false,
          toValue: 1,
          duration: 1500,
        }),
        Animated.timing(scaleAnim, {
          useNativeDriver: false,
          toValue: 0,
          duration: 1500,
        }),
      ]),
    ).start();
  }, [scaleAnim]);

  return (
    <>
      <View style={qrStyles.containerView}>
        <View style={styles.viewPortContainer}>
          {scannerStatus === 'processing' && <ActivityIndicator color={'white'} size={'large'} />}
          {scannerStatus === 'succeeded' && (
            <View style={styles.checkMarkContainer}>
              <QRCheckMark width={QR_READER_VIEWPORT_SIZE / 2} height={QR_READER_VIEWPORT_SIZE / 2} />
              <Text style={styles.successText}>{succeedMessage}</Text>
            </View>
          )}
          {scannerStatus === 'failed' && <Text style={styles.errorSubText}>{error?.message}</Text>}
          {scannerStatus === 'waiting' && isFocused && (
            <QRCodeScanner identifier={props.identifier} onRead={handleScan} />
          )}
        </View>
        <View style={styles.qrAreaContainer}>
          <Animated.View
            style={{
              transform: [
                {
                  scaleX: scaleAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [1.1, 1],
                  }),
                },
                {
                  scaleY: scaleAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [1.1, 1],
                  }),
                },
              ],
            }}>
            <QRArea
              stroke={
                scannerStatus === 'failed'
                  ? errorColor
                  : scannerStatus === 'succeeded'
                  ? succeedColor
                  : props.tenantData.kioskParameters.titleColor
              }
              fill={
                scannerStatus === 'failed'
                  ? errorColor
                  : scannerStatus === 'succeeded'
                  ? succeedColor
                  : props.tenantData.kioskParameters.titleColor
              }
              width={QR_READER_VIEWPORT_SIZE + QR_AREA_MARGIN_SIZE_NATIVE}
              height={QR_READER_VIEWPORT_SIZE + QR_AREA_MARGIN_SIZE_NATIVE}
            />
          </Animated.View>
        </View>
        <View style={qrStyles.subtextViewWeb}>
          {scannerStatus === 'waiting' && (
            <QrSubText companyData={props.tenantData} subText={getTextForKey('vm_welcome_qrCode_scan')} />
          )}
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  welcomeText: {
    marginTop: 30,
    textAlign: 'center',
    fontFamily: 'CircularStd-Book',
    fontWeight: 'normal',
    fontSize: 19,
    color: 'white',
    width: 300,
  },
  successText: {
    marginTop: 30,
    textAlign: 'center',
    fontFamily: 'CircularStd-Book',
    fontWeight: 'normal',
    fontSize: 19,
    color: 'white',
  },
  errorSubText: {
    textAlign: 'center',
    fontFamily: 'CircularStd-Book',
    fontWeight: 'normal',
    fontSize: 19,
    color: '#f24242',
  },
  qrAreaContainer: {
    position: 'absolute',
    top: -QR_AREA_MARGIN_SIZE_NATIVE / 2,
    left: -QR_AREA_MARGIN_SIZE_NATIVE / 2,
  },
  checkMarkContainer: {
    padding: Sizes.xl,
    alignItems: 'center',
  },
  viewPortContainer: {
    width: QR_READER_VIEWPORT_SIZE,
    height: QR_READER_VIEWPORT_SIZE,
    justifyContent: 'center',
  },
});
export default QRCodeReader;
