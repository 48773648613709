import {StyleSheet, View} from 'react-native';
import LabelButton from './LabelButton';
import {CheckinIcon, CheckoutIcon, DeliveryIcon, ReturningIcon} from '../../../../assets/icons/Icons';
import {LanguageSelector} from './LanguageSelector';
import React from 'react';
import {useTenantContext} from '../../../../common/context/TenantContext';

export const BottomBar = () => {
  const {tenantData} = useTenantContext();
  const {getTextForKey} = useTenantContext().translationsProvider;
  return (
    <View style={styles.bottomBar}>
      {tenantData.kioskParameters.touchlessExpectedEnabled && !tenantData.companyParameters.idScanEnabled && (
        <LabelButton
          textColor={tenantData.kioskParameters.titleColor}
          testID={'smallCheckinButtonId'}
          iconLeft={<CheckinIcon width={24} height={27} stroke={tenantData.kioskParameters.titleColor} />}
          text={getTextForKey('vm_welcome_returning_button')}
          onPress={async () => {}}
        />
      )}
      {tenantData.kioskParameters.recognizeBy === 'EMAIL' && (
        <LabelButton
          textColor={tenantData.kioskParameters.titleColor}
          testID={'returningButtonId'}
          iconLeft={<ReturningIcon width={24} height={27} stroke={tenantData.kioskParameters.titleColor} />}
          text={getTextForKey('vm_welcome_returning_button')}
          onPress={async () => {}}
        />
      )}
      {tenantData.kioskParameters.deliveryEnabled && (
        <LabelButton
          textColor={tenantData.kioskParameters.titleColor}
          testID={'deliveryButtonId'}
          iconLeft={<DeliveryIcon width={24} height={27} stroke={tenantData.kioskParameters.titleColor} />}
          text={getTextForKey('vm_welcome_delivery_button')}
          onPress={async () => {}}
        />
      )}
      {tenantData.kioskParameters.checkoutEnabled && (
        <LabelButton
          textColor={tenantData.kioskParameters.titleColor}
          testID={'checkoutButtonId'}
          iconLeft={<CheckoutIcon width={24} height={27} stroke={tenantData.kioskParameters.titleColor} />}
          text={getTextForKey('vm_welcome_checkout_button')}
          onPress={async () => {}}
        />
      )}
      {tenantData.languages.length > 1 && <View style={styles.changeLanguageSeparatorStyle} />}
      {tenantData.languages.length > 1 && <LanguageSelector />}
    </View>
  );
};

const styles = StyleSheet.create({
  bottomBar: {
    height: 32,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  changeLanguageSeparatorStyle: {
    flex: 1,
  },
});
