import {useStorage} from '../../common/storage/UseStorage';
import {useState} from 'react';
import {translationsKey} from '../../common/storage/StorageKeys';
import {IApiError} from '../../common/api/UseApi';
import {IAuthenticatedApi} from '../../common/api/UseAuthenticatedApi';
import {ITranslations} from '../../common/api/models/ITranslations';
import {log} from '../../common/utils/Log';

export interface ITranslationsLoader {
  error: IApiError;

  load(tenantId: string): Promise<ITranslations>;
}

export interface ITranslationsLoaderProps {
  authenticatedApi: IAuthenticatedApi;
}

export const useTranslationsLoader = (props: ITranslationsLoaderProps): ITranslationsLoader => {
  const {storeItem} = useStorage();
  const [error, setError] = useState<IApiError | null>(null);
  const {fetchTranslations} = props.authenticatedApi;

  async function load(tenantId: string): Promise<ITranslations> {
    try {
      log('loading translations for ', tenantId);
      const fetchedTranslations = await fetchTranslations(tenantId);
      await storeItem(translationsKey + tenantId, JSON.stringify(fetchedTranslations));
      log('COMPLETED loading translations for ', tenantId);
      return fetchedTranslations;
    } catch (loadError) {
      log('FAILED loading translations for ', tenantId, 'Error: ', loadError);
      setError(loadError);
    }
  }

  return {
    load,
    error,
  };
};
