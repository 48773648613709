import React from 'react';
import {CenterViewContainer} from '../../../welcomeCommon/CenterViewContainer';
import {SingleTenantTitleAndCheckinButton} from '../leftView/SingleTenantTitleAndCheckinButton';
import {SingleTenantQRCodeReader} from '../qr/SingleTenantQRCodeReader';

export const SingleTenantCenterViewWithQRCodeReader = () => {
  return (
    <CenterViewContainer>
      <SingleTenantTitleAndCheckinButton />
      <SingleTenantQRCodeReader />
    </CenterViewContainer>
  );
};
