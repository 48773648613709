import {IStorage} from './IStorage';
import {log} from '../utils/Log';

export const WebStorage = (): IStorage => {
  async function getItem(key: string): Promise<string | null> {
    try {
      const item = localStorage.getItem(key);
      return item;
    } catch (error) {
      log(error);
    }
  }

  async function storeItem(key: string, value: string): Promise<void> {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      log(error);
    }
  }

  async function removeItem(key: string): Promise<void> {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      log(error);
    }
  }

  return {
    getItem,
    storeItem,
    removeItem,
  };
};
