import React from 'react';
import {Provider as PaperProvider} from 'react-native-paper';
import {ActivatedContextProvider, IActivatedContext} from './src/common/context/IActivatedContext';
import {StandaloneActivatedContext} from './src/common/context/StandaloneActivatedContext';
import {NavigationContainer} from '@react-navigation/native';

export const AppStandalone = () => {
  console.log('standalone mode');
  let activatedContext: IActivatedContext = StandaloneActivatedContext();
  const {content} = activatedContext;

  return (
    <ActivatedContextProvider value={activatedContext}>
      <PaperProvider>
        <NavigationContainer>{content}</NavigationContainer>
      </PaperProvider>
    </ActivatedContextProvider>
  );
};

export default AppStandalone;
