import {IHttpClientOptions} from '@proxyclick/http-client';
import {IActivateResponse} from './responses/IActivateResponse';
import {useApi} from './UseApi';
import {IEnvironment} from '../configuration/IEnvironment';

export interface IUnauthenticatedApiProps {
  environment: IEnvironment;
}

export interface IUnauthenticatedApi {
  activateDeviceWithKey(license: string): Promise<IActivateResponse>;
}

export const useUnauthenticatedApi = (props: IUnauthenticatedApiProps): IUnauthenticatedApi => {
  // TODO: [BEFORE PROD] this clientId might be sensitive to leave here
  const clientId = '93211E1AD74A6E94299FB4C3AD54500C1FAA413D';
  const {buildRequest, request, handleApiError} = useApi();
  const {kioskBaseUrl} = props.environment;

  function buildHeaders(accessToken?: string): {} {
    let headers;
    if (accessToken) {
      headers = {
        'User-Agent': 'Welcome/5.0.0',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + accessToken,
      };
    } else {
      headers = {
        'User-Agent': 'Welcome/5.0.0',
        'Content-Type': 'application/x-www-form-urlencoded',
      };
    }
    return headers;
  }

  function buildUrlEncodedRequest(method: IHttpClientOptions['method'], url: string, accessToken?: string) {
    return buildRequest(method, url, buildHeaders(accessToken));
  }

  const activateDeviceWithKey = async (license: string): Promise<IActivateResponse> => {
    let options: IHttpClientOptions;
    try {
      options = buildUrlEncodedRequest('post', kioskBaseUrl + '/auth/activate');

      options.formData = {
        clientId: clientId,
        license: license,
      };

      const response: IActivateResponse = await request<IActivateResponse>(options);

      return response;
    } catch (e: any) {
      handleApiError(e, options);
    }
  };

  return {activateDeviceWithKey};
};
