﻿export default {
  common_or: 'or',
  common_placeholder_optional: 'Optional',
  common_button_next: 'Next',
  common_button_cancel: 'Cancel',
  common_button_skip: 'Skip',
  common_button_ok: 'OK',
  common_button_back: 'Back',
  common_button_yes: 'Yes',
  common_button_no: 'No',
  common_button_reset: 'Reset',
  common_error_title: 'Error',
  common_error_message_webServiceWrongConnection: 'An error has occurred during data transfer. Please try again',
  common_error_message_noInternetConnection: 'The internet connection has been lost. Please try again.',
  common_error_message_cameraNotAllowed: 'The access to camera needs to be allowed to use this functionnality.',
  common_error_message_invalidCredentials: 'Oops! Username and/or password incorrect. Please try again',
  common_error_message_unauthorizedAccess: 'Unauthorized access',
  common_error_message_missingField: 'Mandatory field',
  common_error_message_incorrectEmail: 'Please enter a correct email address',
  common_error_message_invalidMobile: 'Please enter a valid mobile phone number',
  common_error_message_ForbiddenException: 'Sorry, it seems you do not have sufficient rights to perform this action',
  common_deactivated_title: 'Kiosk deactivated on this iPad',
  common_deactivated_message:
    'This kiosk has been activated on another iPad and is thus no longer active on this one. Please log in again. Thank you!',
  common_expired_title: 'Account expired',
  common_expired_message:
    'Either your trial has expired or the credit card we have on file stopped working. Please upgrade in order to re-activate the account. Contact sales@proxyclick.com if you have any questions. Thank you!',
  common_tooltip_gotIt_button: 'OK',
  common_tooltip_disable_button: 'Disable all tips',
  common_onboarding_message1: 'Proxyclick helps you singleTenantWelcome your visitors exactly the way you want to.',
  common_onboarding_message2:
    'Brand your check-in process and your badges, and ask your visitors only the questions that apply to them.',
  common_onboarding_message3: 'Hosts get notified when their guests arrive and can reply instantly.',
  common_onboarding_message4:
    'Protect your facilities and ensure compliance through emergency lists, watch lists, NDAs, privacy rules ...',
  common_onboarding_message5:
    'Proxyclick does the hard work so you can singleTenantWelcome your visitors with a smile.',
  common_onboarding_login_button: 'Log in',
  common_timeout_title: 'Returning to Home screen',
  common_timeout_content: 'Touch to abort',
  common_enterEmail_title: 'Your email',
  common_enterEmail_email: 'Please enter your email',
  common_signup_title: 'Wecome to Proxyclick',
  common_signup_name: 'Name',
  common_signup_name_placeholder: 'First name Last name',
  common_signup_company: 'Company',
  common_signup_email: 'Email',
  common_signup_password: 'Password',
  common_signup_tryFree_button: 'Submit',
  common_signup_error_name: 'Please enter first and last name',
  common_signup_loading_title: 'Setting up your account …',
  common_signup_loading_message1:
    'You can customize your kiosk by connecting to the Proxyclick dashboard. We just sent you an email with all the details.',
  common_signup_loading_message2: 'From the website you can set up your printer to print badges upon check-in.',
  common_signup_loading_message3: 'You can also enable notifications to be sent to the host upon check-in.',
  common_signup_loading_message4: 'And customize your kiosk with your own colors and your singleTenantWelcome picture.',
  common_setup_login_title: 'Log in with your Proxyclick account',
  common_setup_login_email: 'Email',
  common_setup_login_password: 'Password',
  common_setup_login_forgotPassword_button: 'Forgot your password?',
  common_setup_login_activationCode_button: 'Activate with unique code?',
  common_setup_activationCode_title: 'Activate kiosk with unique code',
  common_setup_activationCode_code: 'Activation code',
  common_setup_activationCode_code_placeholder: 'Eg. YSSFG-CVWVR-JFRWX-HSNMK',
  common_setup_activationCode_activate_button: 'Activate',
  common_setup_activationCode_footer: 'Activation code is handy for SSO admins or for local guards. Learn more.',
  common_setup_activationCode_error_invalidCode: 'Invalid activation code',
  common_setup_location_title: 'Select your location',
  common_setup_location_footer: 'To create a new location, connect to the Proxyclick dashboard',
  common_setup_location_kiosks: 'kiosks',
  common_setup_location_kiosk: 'kiosk',
  common_setup_device_title: 'Select your kiosk',
  common_setup_device_multiTenants: 'Multi-companies',
  common_setup_device_footer: 'To create a new kiosk, connect to the Proxyclick dashboard',
  common_setup_device_locked_title: 'Kiosk already in use',
  common_setup_device_locked_content:
    'This kiosk is already in use on another iPad. Setting it up on the iPad you have in your hands will deactivate it on the other one. Are you sure you want to set up the kiosk on this iPad?',
  common_setup_device_locked_continue_button: 'Set up this kiosk',
  common_setup_ready_title: 'Hello {0}',
  common_setup_ready_header: 'Ready to set up this iPad as your {0} kiosk?',
  common_setup_ready_message: 'It only takes a minute to configure and your kiosk will be up and running',
  common_setup_ready_setup_button: 'Set up',
  common_setup_loading_title: 'Your kiosk is being set up...',
  common_setup_loading_message: 'Configuring...',
  common_setup_loading_footer: 'To customize your kiosk, connect to the Proxyclick dashboard',
  vm_identification_qrCode_button: 'Scan your invitation/pass',
  vm_identification_id_button: 'Scan your ID',
  vm_qrCode_title: 'Scan your badge or Passbook code',
  vm_qrCode_help: 'Hold your code 4-6 in from the camera',
  vm_qrCode_not_recognized: 'Code not recognized',
  vm_multiTenant_title: 'Welcome, which company are you visiting?',
  vm_welcome_title: 'Welcome to {{Company_Name}}',
  vm_welcome_hello_line1: 'Hello,',
  vm_welcome_hello_line2: 'please check in',
  vm_welcome_qrCode_scan: 'Visitors: hold your QR code up to the camera to check in or check out',
  vm_welcome_qrCode_checkedIn: "Welcome {0}, don't forget to check out before leaving",
  vm_welcome_qrCode_checkedOut: 'Thank you for visiting, {0}!',
  vm_welcome_qrCode_notRecognized: 'This QR code is not recognized. Please try again.',
  vm_welcome_qrCode_wrongCompany: 'Your QR code is invalid for this location.',
  vm_welcome_changeLanguage_button: 'Change language',
  vm_welcome_checkin_button: 'Check in',
  vm_welcome_checkout_button: 'Check out',
  vm_welcome_delivery_button: 'Delivery',
  vm_welcome_returning_button: 'Been here before?',
  vm_welcome_tooltip_title: 'Make it your own',
  vm_welcome_tooltip_content:
    'To personalize your kiosk, log in to the Proxyclick dashboard. We just sent you an email with instructions. In the dashboard, you can upload your logo, background image and pick a color so your kiosk reflects your brand identity.',
  vm_checkin_returning_email: "Hello, what's your email address?",
  vm_checkin_returning_email_notRegistered:
    'We could not find a scheduled visit linked to your email address. Please check with reception or try again with a different email address.',
  vm_checkin_method_name: "Hello, what's your name?",
  vm_checkin_method_name_enterFullName: 'Please enter your full name',
  vm_checkin_method_name_notRegistered_continue: 'Not in the list?',
  vm_checkin_method_name_notRegistered_block: 'Not in the list? Please check with reception',
  vm_checkin_method_tooltip_title: 'Visitor recognition',
  vm_checkin_method_tooltip_content:
    'Check in as "Justin Diaz" to see how returning or pre-registered visitors are recognized.\nOr type a random name to see how unexpected visitors are addressed.',
  vm_checkin_idScan_disclaimer_text:
    'As part of our security policy, we need to verify your identity using your ID.\nWe value your privacy, so we will not store the picture of your ID. Reception officers are available to help if you have any questions.',
  vm_checkin_idScan_disclaimer_start_button: "Let's get started",
  vm_checkin_idScan_region_header: 'What region does your ID come from?',
  vm_checkin_idScan_region_0: ' United States',
  vm_checkin_idScan_region_1: ' Canada',
  vm_checkin_idScan_region_2: 'South America',
  vm_checkin_idScan_region_3: ' Europe',
  vm_checkin_idScan_region_4: ' Australia',
  vm_checkin_idScan_region_5: ' Asia',
  vm_checkin_idScan_region_6: ' General Documents',
  vm_checkin_idScan_region_7: ' Africa',
  vm_checkin_idScan_aila_header: 'Please scan your ID',
  vm_checkin_idScan_aila_scan_button: 'Scan ID',
  vm_checkin_idScan_aila_footer: 'This screen is skipped when you use the recommended hardware ({0})',
  vm_checkin_idScan_aila_learnMore: 'Learn more',
  vm_checkin_idScan_front_picture_header_aila: 'Place your ID in the holder so the front faces the camera.',
  vm_checkin_idScan_front_picture_header_noAila: 'Position your ID entirely inside the frame.',
  vm_checkin_idScan_front_picture_take_button: 'Scan front',
  vm_checkin_idScan_back_picture_header_aila: 'Place your ID in the holder so the back faces the camera.',
  vm_checkin_idScan_back_picture_header_noAila: 'Position the back of your ID entirely inside the frame.',
  vm_checkin_idScan_back_picture_take_button: 'Scan back',
  vm_checkin_idScan_match_check: 'Comparing the two pictures',
  vm_checkin_idScan_match_confirm_button: 'Confirm',
  vm_checkin_idScan_match_error_idExpired_header: 'Sorry, your ID seems to have expired.',
  vm_checkin_idScan_match_error_idExpired_message: 'Ask a receptionist for help or try again.',
  vm_checkin_idScan_match_error_idNoFace_header: 'Sorry, we could not identify a face on your ID.',
  vm_checkin_idScan_match_error_idNoFace_message: 'Ask a receptionist for help or try again.',
  vm_checkin_idScan_match_error_pictureNoFace_header: 'Sorry, we could not identify a face on the picture you took.',
  vm_checkin_idScan_match_error_pictureNoFace_message: 'Ask a receptionist for help or try again.',
  vm_checkin_idScan_match_error_default_header: 'Sorry, something went wrong.',
  vm_checkin_idScan_match_error_default_message: 'Ask a receptionist for help or try again.',
  vm_checkin_idScan_editName_title: 'Please fill in your first and last name',
  vm_checkin_idScan_cancel_title: 'Warning',
  vm_checkin_idScan_cancel_message: 'Check-in will be cancelled and you will return to home screen',
  vm_checkin_details_firstname: 'First name',
  vm_checkin_details_lastname: 'Last name',
  vm_checkin_details_companyName: 'Company',
  vm_checkin_details_email: 'Email',
  vm_checkin_welcome_firstTime: 'Welcome {{Visitor_FirstName}}!',
  vm_checkin_welcome_returning: 'Welcome back {{Visitor_FirstName}}!',
  vm_checkin_welcome_lastVisit_today: 'Your last visit was earlier today',
  vm_checkin_welcome_lastVisit_yesterday: 'Your last visit was yesterday',
  vm_checkin_welcome_lastVisit_date: 'Your last visit was on {0}',
  vm_checkin_host_name: 'Who are you visiting?',
  vm_checkin_host_enterFullName: "Please enter your host's full name",
  vm_checkin_host_unknown_button: "I don't know",
  vm_checkin_host_notListed_continue: 'Not in the list',
  vm_checkin_host_notListed_block:
    "If you don't know your host or don't find him/her in the list, please discuss with the receptionist",
  vm_checkin_host_tooltip_title: 'Visitor arrival notification',
  vm_checkin_host_tooltip_content:
    "Type your name here and we'll send you the notification email your employees will get when their visitors arrive.",
  vm_checkin_picture_title: "Let's take your picture!",
  vm_checkin_picture_take_button: 'Take picture',
  vm_checkin_picture_auto_lookCamera: 'Please look at the camera, we will take your visitor picture',
  vm_checkin_picture_auto_countDown: ' ',
  vm_checkin_picture_auto_stayStill: 'Remain still',
  vm_checkin_picture_auto_pictureTaken: 'Picture taken',
  vm_checkin_picture_tooltip_title: 'Optional features',
  vm_checkin_picture_tooltip_content:
    'You can tailor the kiosk to your needs and easily add or remove features such as taking visitor photos or printing badges.',
  vm_checkin_flow_tooltip_title: 'Smart questions',
  vm_checkin_flow_tooltip_content:
    "Ask your visitors anything you need to improve their experience or to make your check-in more secure. You can change the flow of questions depending on visitors' answers. the questions you see are just samples for the demo.",
  vm_checkin_flow_error_message_imageNotAvailable: 'Oops, image currently not available (Internet connection issue)',
  vm_checkin_flow_email: 'Email',
  vm_checkin_flow_licensePlate: 'License plate',
  vm_checkin_flow_company: 'Company',
  vm_checkin_flow_mobile: 'Please enter your mobile number',
  vm_checkin_flow_mobile_info:
    'This will be used to get in touch with you in the event of an emergency while you are at the facility',
  vm_checkin_flow_duration: 'Duration',
  vm_checkin_instructions_sign: 'Sign here with your finger',
  vm_checkin_instructions_agreeTerms_button: 'Agree',
  vm_checkin_instructions_clearSignature_button: 'Clear',
  vm_checkin_instructions_sendCopy_button: 'Send me a copy by email',
  vm_checkin_instructions_sendCopyTo_button: 'A copy will be sent to {0} (Change)',
  vm_checkin_instructions_tooltip_title: 'Signed and sealed',
  vm_checkin_instructions_tooltip_content:
    'Get your guests to accept your visiting rules with a simple tap or signature. You can adapt your terms depending on the type of visitor checking in. Customize this feature and many others in the Proxyclick dashboard.',
  vm_checkin_summary_title: 'Your visit at {{Company_Name}}',
  vm_checkin_summary_visiting: 'Visiting {0}',
  vm_checkin_summary_meetingTime: 'Meeting at {0}',
  vm_checkin_summary_groupStats: '{0} out of {1} people already there',
  vm_checkin_summary_agreementAlreadySigned: 'Agreement already signed',
  vm_checkin_summary_checkin_button: 'Check in now',
  vm_checkin_summary_editDetails_button: 'Edit details',
  vm_checkin_summary_reviewDetails_button: 'Review my visit details',
  vm_checkin_summary_takeNewPicture_button: 'Take new picture',
  vm_checkin_summary_sendPass_button: 'Send me a pass for future visits to my smartphone',
  vm_checkin_summary_sendPassTo_button: 'A permanent pass will be sent to {0} (Change)',
  vm_checkin_summary_countDown: 'You will be checked in after',
  vm_checkin_saveInfo_title: 'Enter your email',
  vm_checkin_saveInfo_email: 'Save my info to speed up my next check-in',
  vm_checkin_saveInfo_email_placeholder: 'Enter your email address',
  vm_checkin_confirmation_title: 'Thank you!',
  vm_checkin_confirmation_header_withPrint: 'Your badge is printing',
  vm_checkin_confirmation_header_withoutPrint: 'You have been registered!',
  vm_checkin_confirmation_message: 'Please have a seat, {{Host_FirstName}} will be right with you',
  vm_checkin_confirmation_takeBackID: "Please don't forget your ID",
  vm_checkin_error_message_unableToCheckIn:
    'An error occurred while finalizing your check-in, please contact the reception',
  vm_checkout_method_title: "Let's check out",
  vm_checkout_method_name: 'Please enter your name in order to check out',
  vm_checkout_method_name_notFound: 'Not found',
  vm_checkout_confirmation_title: 'Thank you for your visit!',
  vm_checkout_confirmation_header: 'Goodbye {{Visitor_FirstName}}!',
  vm_checkout_confirmation_message: "You've been checked out. We hope you enjoyed your visit.",
  vm_infopack_likeCheckIn: 'Liked this check-in? Tap here to learn more',
  vm_infopack_likeProcess: 'Liked this process? Tap here to learn more',
  vm_infopack_email: 'Please enter your email so we can send you information about Proxyclick',
  vm_infopack_send_button: 'Send me info',
  vm_infopack_confirmation: 'Thank you! \nWe sent information to {0}.',
  vm_product_footer_partner: "You're using Proxyclick Welcome, brought to you by {0}",
  vm_product_footer_noPartner: "You're using Proxyclick Welcome",
  vm_product_footer_learnMore: 'Learn more',
  dm_delivery_recipient_name: 'Who is this package for?',
  dm_delivery_recipient_unknown_button: "I don't know",
  dm_delivery_confirmation_title: 'Thank you!',
  dm_delivery_confirmation_knownRecipient: '{{Host_Name}} has been informed that you delivered a package',
  dm_delivery_confirmation_unknownRecipient:
    'A notification has been sent to inform {{Company_Name}} you delivered a package for them',
  em_multiTenant_notRegistered_button: 'Tap here to check in manually',
  em_multiTenant_alreadyRegistered_button: 'Tap here if you are an employee or have received a QR code to check in',
  vm_welcome_qrCode_show: 'Employees: scan this QR code with Proovr to check in or check out',
  vm_welcome_touchless_qrCode_show: 'Unregistered Visitors: Scan this QR code for touchless check-in',
  vm_welcome_touchless_unexpected_qrCode_show: 'Scan this QR code with your phone to check in as a visitor',
  vm_welcome_touchless_employee_qrCode_show: 'Scan this QR code with Proovr to check in as an employee',
  vm_welcome_touchless_unexpected_and_employee_qrCode_show:
    'Scan this QR code with Proovr (employees) or your phone (visitors) to check in',
  vm_checkin_video_subtitle: 'Watch the full video to continue your check-in.',
  vm_checkin_video_duration: 'Duration',
};
