import httpClient, {IHttpClientOptions, IHttpClientResponse} from '@proxyclick/http-client';
import {log} from '../utils/Log';

export interface IApiError {
  statusCode: number;
  message: string;
  type: string;
}

export class ApiError extends Error implements IApiError {
  statusCode: number;
  message: string;
  type: string;

  constructor(statusCode: number, message: string, type: string) {
    super(message);
    this.statusCode = statusCode;
    this.message = message;
    this.type = type;
  }
}

interface IApi {
  buildRequest: (method: IHttpClientOptions['method'], url: string, headers: {}, payload?: any) => IHttpClientOptions;
  handleApiError: (error: any, options?: IHttpClientOptions) => IApiError;
  request: <T>(options: IHttpClientOptions) => Promise<T>;
  internalServerErrorApiException: {};
}

export const useApi = (): IApi => {
  const internalServerErrorApiException = {
    statusCode: 500,
    data: {
      message: 'Unknown error',
      type: 'InternalServerError',
    },
  };

  function handleApiError(e: any, options?: IHttpClientOptions): IApiError {
    log('An error occurred in an Api call', e, options);
    throw new ApiError(e.status, e.data.message, e.data.type);
  }

  function buildRequest(
    method: IHttpClientOptions['method'],
    url: string,
    headers: {},
    payload?: any,
  ): IHttpClientOptions {
    let req: IHttpClientOptions = {
      method: method,
      url: url,
      headers: headers,
    };
    if (payload) {
      req.json = payload;
    }
    return req;
  }

  function requestSucceeded(response: IHttpClientResponse<any>): boolean {
    return response.data !== undefined && response.data !== null;
  }

  async function request<T>(options: IHttpClientOptions): Promise<T> {
    const response: IHttpClientResponse<T> = await httpClient.request<T>(options);

    let responseObject: T;

    try {
      if (requestSucceeded(response)) {
        responseObject = response.data;
      } else {
        throw internalServerErrorApiException;
      }
    } catch (e: any) {
      throw internalServerErrorApiException;
    }

    return responseObject;
  }

  return {
    buildRequest,
    request,
    handleApiError,
    internalServerErrorApiException,
  };
};
