import {FlatList, StyleSheet, View} from 'react-native';
import React, {useEffect, useState} from 'react';
import {useActivatedContext} from '../../../../common/context/IActivatedContext';
import {MultiTenantCell} from './MutlTenantCell';
import {InputSearchField, Sizes} from '@proxyclick/hello-react-native';

export interface IMultiTenantGridViewProps {
  fullScreen: boolean;
}

export const MultiTenantGridView = (props: IMultiTenantGridViewProps) => {
  const {tenants} = useActivatedContext().tenants;
  const [listData, setListData] = useState(Array.from(tenants.values()));
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (searchText) {
      setListData(
        Array.from(tenants.values()).filter(td => td.company.name.toLowerCase().includes(searchText.toLowerCase())),
      );
    } else {
      setListData(Array.from(tenants.values()));
    }
  }, [searchText]);

  return (
    <View style={styles.container}>
      <View style={styles.searchFieldContainer}>
        <InputSearchField containerStyle={styles.input} text={searchText} onTextChanged={t => setSearchText(t)} />
      </View>
      <View style={styles.gridContainer}>
        <FlatList
          contentContainerStyle={styles.list}
          data={listData}
          renderItem={({item}) => <MultiTenantCell company={item.company} />}
          numColumns={props.fullScreen ? 3 : 2}
          keyExtractor={(item, index) => index.toString()}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },

  searchFieldContainer: {
    paddingTop: Sizes.xxl,
    paddingBottom: Sizes.xxl,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  bottom: {},
  gridContainer: {
    flex: 1,
  },
  list: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    width: 480,
  },
});
