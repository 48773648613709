import {MultiTenantCenterViewWithQRCodeAndReader} from './MultiTenantCenterViewWithQRCodeAndReader';
import {MultiTenantCenterViewWithQRCode} from './MultiTenantCenterViewWithQRCode';
import {MultiTenantCenterViewWithQRCodeReader} from './MultiTenantCenterViewWithQRCodeReader';
import {MultiTenantCenterViewWithGrid} from './MultiTenantCenterViewWithGrid';
import {StyleSheet, View} from 'react-native';
import React from 'react';
import {useCenterViewLayoutForMultiTenant} from '../UseCenterViewLayoutForMultiTenant';

export const MultiTenantCenterView = () => {
  const {showQRCodeReader, showQRCode} = useCenterViewLayoutForMultiTenant();
  return (
    <View style={styles.container} testID={'welcomeCenterViewId'}>
      {showQRCode && showQRCodeReader && <MultiTenantCenterViewWithQRCodeAndReader />}
      {showQRCode && !showQRCodeReader && <MultiTenantCenterViewWithQRCode />}
      {!showQRCode && showQRCodeReader && <MultiTenantCenterViewWithQRCodeReader />}
      {!showQRCode && !showQRCodeReader && <MultiTenantCenterViewWithGrid />}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
});
