import {usePubnubConnector} from '../pubnub/UsePubnubConnector';
import {useDevice} from '../configuration/UseDevice';
import {useTenants} from '../configuration/UseTenants';
import {IActivatedContext} from './IActivatedContext';
import {useAuthenticatedApi} from '../api/UseAuthenticatedApi';
import {useUnauthenticatedApi} from '../api/UseUnauthenticatedApi';
import {useAuthenticator} from '../authenticator/UseAuthenticator';
import {useStandaloneEnvironment} from '../configuration/UseStandaloneEnvironment';
import {useCompany} from '../configuration/UseCompany';
import EnvironmentResolver from '../configuration/EnvironmentResolver';
import {log} from '../utils/Log';
import React, {useEffect, useState} from 'react';
import {IKioskInstance} from '@proxyclick/data-model/src/all-models';
import {ITenantData} from '../api/models/ITenantData';
import {MultiTenantKioskNavigator} from '../navigation/MultiTenantKioskNavigator';
import {KioskNavigator} from '../navigation/KioskNavigator';
import {UnactivatedNavigator} from '../navigation/UnactivatedNavigator';

export const StandaloneActivatedContext = (): IActivatedContext => {
  const [content, setContent] = useState<JSX.Element>(<UnactivatedNavigator />);
  const [initialized, setInitialized] = useState(false);
  const authenticator = useAuthenticator();

  const environmentResolver = new EnvironmentResolver();
  log('Environment =', environmentResolver.resolveEnvironment());
  const environment = useStandaloneEnvironment({
    environment: environmentResolver.resolveEnvironment(),
  });

  const authenticatedApi = useAuthenticatedApi({
    authenticator,
    environment,
  });

  const {isAuthenticated} = authenticatedApi;

  const unauthenticatedApi = useUnauthenticatedApi({
    environment,
  });

  const deviceProvider = useDevice({
    authenticatedApi,
  });

  const pubnubConnector = usePubnubConnector({
    authenticatedApi,
  });

  const companyDataProvider = useCompany({
    authenticatedApi,
    pubnubConnector,
  });

  const tenants = useTenants({
    authenticatedApi,
    pubnubConnector,
  });

  useEffect(() => {
    (async () => {
      if (isAuthenticated && deviceProvider.activated) {
        console.log('initializing...');
        await initialize();
      }
    })();
  }, [isAuthenticated, deviceProvider.activated]);

  async function initialize(): Promise<void> {
    const device: IKioskInstance = await deviceProvider.initialize();
    const companyData: ITenantData = await companyDataProvider.initialize(device);
    await tenants.initialize(device, companyData);
    if (device.multiTenant) {
      console.log('multiTenant navigator');
      setContent(<MultiTenantKioskNavigator />);
    } else {
      console.log('kiosk navigator');
      setContent(<KioskNavigator />);
    }
  }

  return {
    authenticator,
    environment,
    authenticatedApi,
    unauthenticatedApi,
    deviceProvider,
    companyDataProvider,
    tenants,
    pubnubConnector,
    content,
    initialize,
    initialized,
  };
};
