import {useKioskParametersLoader} from './UseKioskParametersLoader';
import {useEmployeeParametersLoader} from './UseEmployeeParametersLoader';
import {useCompanyParametersLoader} from './UseCompanyParametersLoader';
import {useTranslationsLoader} from './UseTranslationsLoader';
import {IAuthenticatedApi} from '../../common/api/UseAuthenticatedApi';
import {useLanguagesLoader} from './UseLanguagesLoader';

export interface IParamsLoaderProps {
  authenticatedApi: IAuthenticatedApi;
}

export interface IParamsLoader {
  loadDataForTenant(tenantId: string): Promise<any>;
  loadCompanyParametersForTenant(tenantId: string): Promise<any>;
  loadKioskParametersForTenant(tenantId: string): Promise<any>;
  loadEmployeeParametersForTenant(tenantId: string): Promise<any>;
  loadTranslationsForTenant(tenantId: string): Promise<any>;
  loadLanguagesForTenant(tenantId: string): Promise<any>;
}

export const useParamsLoader = (props: IParamsLoaderProps): IParamsLoader => {
  const companyParametersLoader = useCompanyParametersLoader({
    authenticatedApi: props.authenticatedApi,
  });
  const kioskParametersLoader = useKioskParametersLoader({
    authenticatedApi: props.authenticatedApi,
  });
  const employeeParametersLoader = useEmployeeParametersLoader({
    authenticatedApi: props.authenticatedApi,
  });
  const translationsLoader = useTranslationsLoader({
    authenticatedApi: props.authenticatedApi,
  });
  const languagesLoader = useLanguagesLoader({
    authenticatedApi: props.authenticatedApi,
  });

  async function loadDataForTenant(tenantId: string) {
    return {
      companyParameters: await companyParametersLoader.load(tenantId),
      kioskParameters: await kioskParametersLoader.load(tenantId),
      employeeParameters: await employeeParametersLoader.load(tenantId),
      translations: await translationsLoader.load(tenantId),
      languages: await languagesLoader.load(tenantId),
    };
  }

  // PUBNUB LOADERS
  async function loadCompanyParametersForTenant(tenantId: string) {
    return await companyParametersLoader.load(tenantId);
  }
  async function loadKioskParametersForTenant(tenantId: string) {
    return await kioskParametersLoader.load(tenantId);
  }
  async function loadEmployeeParametersForTenant(tenantId: string) {
    return await employeeParametersLoader.load(tenantId);
  }
  async function loadTranslationsForTenant(tenantId: string) {
    return await translationsLoader.load(tenantId);
  }
  async function loadLanguagesForTenant(tenantId: string) {
    return await languagesLoader.load(tenantId);
  }

  return {
    loadDataForTenant,
    loadCompanyParametersForTenant,
    loadKioskParametersForTenant,
    loadEmployeeParametersForTenant,
    loadTranslationsForTenant,
    loadLanguagesForTenant,
  };
};
