import React from 'react';
import {MultiTenantView} from './components/MultiTenantView';
import {ScreenWithBackground} from '../welcomeCommon/ScreenWithBackground';
import {StyleSheet, View} from 'react-native';
import {Sizes} from '@proxyclick/hello-react-native';

export const MultiTenantScreen = () => {
  console.log('rendering multiTenantWelcome screen');
  return (
    <ScreenWithBackground>
      <View style={styles.mainStackView} testID={'homeViewId'}>
        <MultiTenantView />
      </View>
    </ScreenWithBackground>
  );
};

const styles = StyleSheet.create({
  mainStackView: {
    flex: 1,
    flexDirection: 'column',
    marginHorizontal: Sizes.xxl * 2,
  },
  topBar: {
    height: Sizes.xxl,
  },
});
