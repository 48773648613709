import React from 'react';
import {View, StyleSheet} from 'react-native';
import {SingleTenantCenterViewWithQRCode} from './SingleTenantCenterViewWithQRCode';
import {SingleTenantCenterViewWithQRCodeAndReader} from './SingleTenantCenterViewWithQRCodeAndReader';
import {SingleTenantCenterViewWithTitle} from './SingleTenantCenterViewWithTitle';
import {SingleTenantCenterViewWithQRCodeReader} from './SingleTenantCenterViewWithQRCodeReader';
import {useCenterViewLayoutForSingleTenant} from '../UseCenterViewLayoutForSingleTenant';

export const WelcomeCenterView = () => {
  const {showQRCodeReader, showQRCode, showCheckinQRCodeAndQRCodeReader} = useCenterViewLayoutForSingleTenant();
  return (
    <View style={styles.container} testID={'welcomeCenterViewId'}>
      {showCheckinQRCodeAndQRCodeReader && <SingleTenantCenterViewWithQRCodeAndReader />}
      {showQRCode && !showQRCodeReader && <SingleTenantCenterViewWithQRCode />}
      {!showQRCode && showQRCodeReader && <SingleTenantCenterViewWithQRCodeReader />}
      {!showQRCode && !showQRCodeReader && <SingleTenantCenterViewWithTitle />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {flex: 1, justifyContent: 'center'},
});
