export const accessTokenKey: string = 'ACCESS_TOKEN_KEY';
export const companyIdKey: string = 'COMPANY_ID_KEY';
export const companyKey: string = 'COMPANY_KEY';
export const deviceIdKey: string = 'DEVICE_ID_KEY';
export const deviceKey: string = 'DEVICE_KEY';
export const tenantsKey: string = 'TENANTS_KEY';

export const kioskParametersKey: string = 'KIOSK_PARAMETERS_KEY_';
export const companyParametersKey: string = 'COMPANY_PARAMETERS_KEY_';
export const employeeParametersKey: string = 'EMPLOYEE_PARAMETERS_KEY_';
export const translationsKey: string = 'TRANSLATIONS_KEY_';
export const languagesKey: string = 'LANGUAGES_KEY_';
