import React from 'react';
import {useActivatedContext} from '../../../../common/context/IActivatedContext';
import {CheckinQRCode} from '../../../welcomeCommon/CheckinQRCode';
import {useTranslationsProvider} from '../../../../common/translations/useTranslationsProvider';

export const MultiTenantCheckinQRCode = () => {
  const {companyData} = useActivatedContext().companyDataProvider;
  const translations = useTranslationsProvider({tenantData: companyData});
  return <CheckinQRCode tenantData={companyData} isMultiTenant={true} translations={translations} />;
};
