import PubNub from 'pubnub';
import EnvironmentResolver from '../../common/configuration/EnvironmentResolver';

class PubnubConfigResolver {
  pubNubConfigProd = require('./config/pubnub.json');
  pubNubConfig001 = require('./config/pubnub-001.json');
  pubNubConfig002 = require('./config/pubnub-002.json');
  pubNubConfig003 = require('./config/pubnub-003.json');
  pubNubConfig004 = require('./config/pubnub-004.json');
  pubNubConfig005 = require('./config/pubnub-005.json');
  pubNubConfigQA = require('./config/pubnub-qa.json');
  pubNubConfigStaging = require('./config/pubnub-staging.json');
  pubNubConfigKioskDevelop = require('./config/pubnub-kiosk-develop.json');
  pubNubConfigWebDevelop = require('./config/pubnub-web-develop.json');
  pubNubConfigWebMaster = require('./config/pubnub-web-master.json');

  resolvePubnubConfig(): any {
    const environmentResolver = new EnvironmentResolver();
    const environment = environmentResolver.resolveEnvironment();
    let pubNubClient: any;

    switch (environment) {
      case '001': {
        pubNubClient = new PubNub(this.pubNubConfig001.Kiosk.keySet);
        break;
      }
      case '002': {
        pubNubClient = new PubNub(this.pubNubConfig002.Kiosk.keySet);
        break;
      }
      case '003': {
        pubNubClient = new PubNub(this.pubNubConfig003.Kiosk.keySet);
        break;
      }
      case '004': {
        pubNubClient = new PubNub(this.pubNubConfig004.Kiosk.keySet);
        break;
      }
      case '005': {
        pubNubClient = new PubNub(this.pubNubConfig005.Kiosk.keySet);
        break;
      }
      case 'qa': {
        pubNubClient = new PubNub(this.pubNubConfigQA.Kiosk.keySet);
        break;
      }
      case 'staging': {
        pubNubClient = new PubNub(this.pubNubConfigStaging.Kiosk.keySet);
        break;
      }
      case 'kiosk-develop': {
        pubNubClient = new PubNub(this.pubNubConfigKioskDevelop.Kiosk.keySet);
        break;
      }
      case 'web-develop': {
        pubNubClient = new PubNub(this.pubNubConfigWebDevelop.Kiosk.keySet);
        break;
      }
      case 'web-master': {
        pubNubClient = new PubNub(this.pubNubConfigWebMaster.Kiosk.keySet);
        break;
      }
      default: {
        pubNubClient = new PubNub(this.pubNubConfigProd.Kiosk.keySet);
        break;
      }
    }
    return pubNubClient;
  }
}

export default PubnubConfigResolver;
