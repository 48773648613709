import {useEffect, useState} from 'react';
import {useTenantContext} from '../../../common/context/TenantContext';

export interface IUseCenterViewLayout {
  showQRCode: boolean;
  showQRCodeReader: boolean;
  showCheckinQRCodeAndQRCodeReader: boolean;
}

export const useCenterViewLayoutForSingleTenant = (): IUseCenterViewLayout => {
  const {tenantData} = useTenantContext();
  const [showQRCode, setShowQRCode] = useState<boolean>(
    tenantData.kioskParameters.touchlessUnexpectedEnabled || tenantData.employeeParameters.enabled,
  );
  const [showQRCodeReader, setShowQRCodeReader] = useState<boolean>(
    tenantData.kioskParameters.touchlessExpectedEnabled,
  );
  const [showCheckinQRCodeAndQRCodeReader, setShowCheckinQRCodeAndQRCodeReader] = useState<boolean>(
    showQRCode && showQRCodeReader,
  );

  useEffect(() => {
    setShowQRCode(tenantData.kioskParameters.touchlessUnexpectedEnabled || tenantData.employeeParameters.enabled);
    setShowQRCodeReader(tenantData.kioskParameters.touchlessExpectedEnabled);
    setShowCheckinQRCodeAndQRCodeReader(
      tenantData.kioskParameters.touchlessExpectedEnabled &&
        (tenantData.kioskParameters.touchlessUnexpectedEnabled || tenantData.employeeParameters.enabled),
    );
  }, [tenantData.kioskParameters, tenantData.employeeParameters]);

  return {
    showQRCode,
    showQRCodeReader,
    showCheckinQRCodeAndQRCodeReader,
  };
};
