import {useMemo, useState} from 'react';
import {IEnvironment, IStandaloneEnvironmentProps} from './IEnvironment';

export const useStandaloneEnvironment = (props: IStandaloneEnvironmentProps | null): IEnvironment => {
  const [environment, setEnvironment] = useState<string>(props.environment);

  const apiBaseUrl = useMemo(() => {
    if (environment.includes('00')) {
      return 'https://api-' + environment + '.proxyclick-dev.com/full/';
    } else if (environment === 'qa' || environment === 'staging') {
      return 'https://api.proxyclick-' + environment + '.com/full/';
    } else if (environment === 'kiosk-develop') {
      return 'https://api-kiosk-develop.proxyclick-test.com/full/';
    } else if (environment === 'web-develop') {
      return 'https://api-web-develop.proxyclick-test.com/full/';
    } else if (environment === 'web-master') {
      return 'https://api-web-master.proxyclick-test.com/full/';
    } else {
      return 'https://api.proxyclick.com/full/';
    }
  }, [environment]);

  const kioskBaseUrl = useMemo(() => {
    if (environment.includes('00')) {
      return 'https://kiosk-' + environment + '.proxyclick-dev.com/';
    } else if (environment === 'qa' || environment === 'staging') {
      return 'https://kiosk.proxyclick-' + environment + '.com/';
    } else if (environment === 'kiosk-develop') {
      return 'https://kiosk-kiosk-develop.proxyclick-test.com/';
    } else if (environment === 'web-develop') {
      return 'https://kiosk-web-develop.proxyclick-test.com/';
    } else if (environment === 'web-master') {
      return 'https://kiosk-web-master.proxyclick-test.com/';
    } else {
      return 'https://kiosk.proxyclick.com/';
    }
  }, [environment]);

  return {
    environment,
    setEnvironment,
    apiBaseUrl,
    kioskBaseUrl,
  };
};
