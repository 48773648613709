import {SingleTenantWelcomeTitle} from '../title/SingleTenantWelcomeTitle';
import {CheckinButton} from './CheckinButton';
import {StyleSheet, View} from 'react-native';
import React from 'react';

export const SingleTenantTitleAndCheckinButton = () => {
  return (
    <View style={styles.centerViewLeft}>
      <SingleTenantWelcomeTitle multiLine={true} />
      <CheckinButton onPress={() => {}} />
    </View>
  );
};

const styles = StyleSheet.create({
  centerViewLeft: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
});
