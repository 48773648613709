import {log} from '../../common/utils/Log';
import {IVisit} from '@proxyclick/data-model/index';
import {useActivatedContext} from '../../common/context/IActivatedContext';
import {DateUtilities} from '../../common/utils/DateUtilities';
import {ITenantData} from '../../common/api/models/ITenantData';
import {IVmParams} from '@proxyclick/data-model/src/all-models';
import {useTranslationsProvider} from '../../common/translations/useTranslationsProvider';

export enum VisitStatus {
  expected = 1,
  checked_in = 2,
  checked_out = 3,
  onsite_status = 4,
  review_status = 5,
  denied_status = 6,
}

export interface IQRCodeDecoder {
  processQRCode: (qrCodeData: string) => Promise<IQrCodeProcessResult>;
}

export interface IQrCodeDecoderProps {
  isCompanyFound: (companyId: string) => boolean;
  tenantData: ITenantData;
}

interface IQrCodeProcessResult {
  visitStatus: VisitStatus;
  matchedVisit: IVisit | undefined;
}

interface IQrCodeData {
  meetingId: string | null;
  visitorId: string | null;
  companyId: string | null;
}

const SEPARATOR_ACS_PXC_DATA = '|';
const SEPARATOR_VISIT_INFORMATION = ',';

export const useQRCodeDecoder = (props: IQrCodeDecoderProps): IQRCodeDecoder => {
  let qrCodeData: IQrCodeData | undefined;
  const device = useActivatedContext().deviceProvider.device;
  const api = useActivatedContext().authenticatedApi;
  const {getTextForKey} = useTranslationsProvider({tenantData: props.tenantData});
  const {tenants} = useActivatedContext().tenants;

  async function processQRCode(rawData: string): Promise<IQrCodeProcessResult> {
    let matchedVisit: IVisit | undefined;
    let status: VisitStatus;
    let tenantData: ITenantData;
    if (rawData) {
      log('Decode QR code :' + rawData);
      qrCodeData = parseData(rawData);
      if (!isValid()) {
        throw new Error(getTextForKey('vm_welcome_qrCode_notRecognized'));
      }

      if (props.isCompanyFound(qrCodeData.companyId)) {
        tenantData = tenants.get(qrCodeData.companyId);
      } else {
        throw new Error(getTextForKey('vm_welcome_qrCode_wrongCompany'));
      }

      log('data parsed: ', qrCodeData);
      const response = await api.fetchVisitsForToday(qrCodeData.companyId, 1, 1000);
      if (response.visits?.length <= 0) {
        throw new Error(getTextForKey('vm_checkin_qrcode_no_checkin_allowed'));
      }
      log('visits for today: ', response.visits);
      if (isInvitation()) {
        matchedVisit = await getVisit(response.visits);
        log('matched visit: ', matchedVisit);
        if (canSetCheckout(matchedVisit)) {
          log('checkout visit: ', matchedVisit);
          await api.checkoutForCompanyAndVisit(matchedVisit.company.id, matchedVisit.id);
          status = VisitStatus.checked_out;
        } else {
          if (canSetOnSite(matchedVisit, tenantData.companyParameters)) {
            log('on site visit: ', matchedVisit);
            await api.onSiteForCompanyAndVisit(matchedVisit.company.id, matchedVisit.id);
            status = VisitStatus.onsite_status;
            //api set onsite
          } else if (canSetCheckin(matchedVisit, tenantData.companyParameters)) {
            log('checkin visit: ', matchedVisit);
            await api.checkinForCompanyAndVisit(matchedVisit.company.id, matchedVisit.id, device.location);
            status = VisitStatus.checked_in;
          }
        }
        if (!matchedVisit) {
          throw new Error(getTextForKey('vm_checkin_qrcode_no_checkin_allowed'));
        }
      } else if (isPermanentPass()) {
        throw new Error('Permanent pas not implemented yet.');
      }

      return {matchedVisit: matchedVisit, visitStatus: status};
    }
  }

  async function getVisit(visits: IVisit[]): Promise<IVisit | null> {
    let visit: IVisit | null = null;
    visits.forEach(v => {
      if (v.meeting?.id === qrCodeData.meetingId && v.visitor?.id === qrCodeData.visitorId) {
        log('visit found: ', v);
        visit = v;
      }
    });

    if (!visit) {
      visit = await getVisitOnServer();
    }

    return visit;
  }

  function canSetCheckout(visit: IVisit): boolean {
    return visit?.status?.id === VisitStatus.checked_in;
  }

  function canSetCheckin(visit: IVisit, companyParameters: IVmParams): boolean {
    return (
      (visit?.status?.id === VisitStatus.expected && !companyParameters.onSiteStatus) ||
      visit?.status?.id === VisitStatus.onsite_status
    );
  }

  function canSetOnSite(visit: IVisit, companyParameters: IVmParams): boolean {
    return visit?.status?.id === VisitStatus.expected && companyParameters.onSiteStatus;
  }

  async function getVisitOnServer(): Promise<IVisit | null> {
    const visitId = `${DateUtilities.today()}/${qrCodeData.meetingId}/${qrCodeData.visitorId}`;
    return await api.fetchVisitById(qrCodeData.companyId, visitId);
  }

  function parseData(rawData: string): IQrCodeData {
    const visitInformation = extractVisitInformation(rawData);
    return {
      meetingId: visitInformation.find(e => e.startsWith('M-')),
      visitorId: visitInformation.find(e => e.startsWith('V-')),
      companyId: visitInformation.find(e => e.startsWith('CO-')),
    };
  }

  function extractVisitInformation(information: string): string[] | null {
    let visitInformation: string[] | null;
    const extracted = information.split(SEPARATOR_ACS_PXC_DATA);
    //in case an ACS code prefix the visit information
    if (extracted.length > 1) {
      visitInformation = extracted[1].split(SEPARATOR_VISIT_INFORMATION);
    } else if (extracted.length === 1) {
      visitInformation = extracted[0].split(SEPARATOR_VISIT_INFORMATION);
    }
    return visitInformation;
  }

  function isValid(): boolean {
    return qrCodeData.visitorId !== undefined && qrCodeData.companyId !== undefined;
  }

  function isInvitation(): boolean {
    return qrCodeData.meetingId !== undefined && qrCodeData.visitorId !== undefined;
  }

  function isPermanentPass(): boolean {
    return qrCodeData.meetingId === undefined && qrCodeData.visitorId !== undefined;
  }

  return {processQRCode};
};
