import {IEmployeeParametersDataLoader, IEmployeeParametersLoaderProps} from './IEmpolyeeParametersLoader';
import {IEmployeeParams} from '@proxyclick/data-model/src/employee/employee';
import {useStorage} from '../../common/storage/UseStorage';
import {employeeParametersKey} from '../../common/storage/StorageKeys';
import {log} from '../../common/utils/Log';

export const useEmployeeParametersLoader = (props: IEmployeeParametersLoaderProps): IEmployeeParametersDataLoader => {
  const {storeItem} = useStorage();
  const {fetchEmployeeParameters} = props.authenticatedApi;

  async function load(tenantId: string): Promise<IEmployeeParams> {
    try {
      log('loading employee parameters for ', tenantId);
      const theEmployeeParameters = await fetchEmployeeParameters(tenantId);
      await storeItem(employeeParametersKey + tenantId, JSON.stringify(theEmployeeParameters));
      log('COMPLETED loading employee parameters for ', tenantId);
      return theEmployeeParameters;
    } catch (loadError) {
      log('FAILED loading employee parameters for ', tenantId, 'Error: ', loadError);
      throw loadError;
    }
  }

  return {
    load,
  };
};
