import {View, StyleSheet} from 'react-native';
import React, {useEffect, useState} from 'react';
import MaskInput from 'react-native-mask-input';
import {useDeviceActivator} from './UseDeviceActivator';
import {Colors, Sizes, LabelSmall, ButtonDefault} from '@proxyclick/hello-react-native';
import {Button, Dialog, Paragraph, Portal} from 'react-native-paper';

const minTitleWidth = 450;
const minDialogWidth = 350;

export const ActivateKioskScreen = () => {
  const {error, activateDevice} = useDeviceActivator();
  const [inputActivationKeyText, setInputActivationKeyText] = useState('EERZCUXLYMLBYMFBNRVJ');
  const [visible, setVisible] = useState(false);
  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);

  useEffect(() => {
    if (error) {
      showDialog();
    }
  }, [error]);

  return (
    <View style={styles.container} testID={'activationScreenTestID'}>
      <LabelSmall
        testID={'titleLabelId'}
        text={'Enter your activation code'}
        textAlign={'center'}
        containerStyle={styles.screenTitle}
      />

      <MaskInput
        testID={'inputId'}
        autoFocus={true}
        autoCorrect={false}
        placeholder="EG. ABCDE - FGHIJ - KLMNO - PQRST - UVWXYZ"
        style={styles.activationKeyInput}
        value={inputActivationKeyText.toUpperCase()}
        onChangeText={(masked, unmasked) => {
          setInputActivationKeyText(unmasked);
        }}
        mask={[
          /[aA-zZ]/,
          /[aA-zZ]/,
          /[aA-zZ]/,
          /[aA-zZ]/,
          /[aA-zZ]/,
          '-',
          /[aA-zZ]/,
          /[aA-zZ]/,
          /[aA-zZ]/,
          /[aA-zZ]/,
          /[aA-zZ]/,
          '-',
          /[aA-zZ]/,
          /[aA-zZ]/,
          /[aA-zZ]/,
          /[aA-zZ]/,
          /[aA-zZ]/,
          '-',
          /[aA-zZ]/,
          /[aA-zZ]/,
          /[aA-zZ]/,
          /[aA-zZ]/,
          /[aA-zZ]/,
        ]}
      />

      <ButtonDefault
        widthFitsLabel={true}
        isEnabled={inputActivationKeyText.length === 20}
        onPress={async () => {
          await activateDevice(inputActivationKeyText);
        }}
        text={'Activate'}
        isFilled={true}
        containerStyle={styles.buttonContainer}
        testID={'activateButtonId'}
      />
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={styles.dialog}>
          <Dialog.Content>
            <Paragraph>{error ? error.message : ''}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={hideDialog}>Done</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.neutralOff,
  },
  screenTitle: {
    width: minTitleWidth,
    marginBottom: Sizes.s,
  },
  buttonContainer: {
    marginTop: Sizes.l,
  },
  activationKeyInput: {
    textAlign: 'center',
    height: Sizes.xl,
    width: minTitleWidth,
    borderColor: Colors.neutralLightAlpha,
    borderWidth: 1,
    borderRadius: Sizes.xl / 2,
  },
  dialog: {
    minWidth: minDialogWidth,
    alignSelf: 'center',
  },
});
