import {useEffect, useState} from 'react';
import {accessTokenKey} from '../storage/StorageKeys';
import {useStorage} from '../storage/UseStorage';

export interface IAuthenticatorProps {
  token?: string | null;
}

export interface IAuthenticator {
  accessToken: string | null;
  setAccessToken(token: string): void;
  logout: () => Promise<void>;
}

export const useAuthenticator = (props?: IAuthenticatorProps): IAuthenticator => {
  const {getItem} = useStorage();
  const [accessToken, setAccessToken] = useState<string | null>(props?.token);

  useEffect(() => {
    (async () => {
      if (!props?.token) {
        const token: string = await getItem(accessTokenKey);
        if (token) {
          setAccessToken(token);
        }
      }
    })();
  }, []);

  async function logout(): Promise<void> {
    try {
      setAccessToken(null);
    } catch (error) {
      console.log('logout, caught error: ', error);
      throw error;
    }
  }

  return {
    accessToken,
    setAccessToken,
    logout,
  };
};
