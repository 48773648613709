import React from 'react';
import {WelcomeTitle} from '../../../welcomeCommon/WelcomeTitle';
import {useActivatedContext} from '../../../../common/context/IActivatedContext';
import {useTranslationsProvider} from '../../../../common/translations/useTranslationsProvider';

export interface MultiTenantWelcomeTitleProps {
  multiLine: boolean;
}

export const MultiTenantWelcomeTitle = (props: MultiTenantWelcomeTitleProps) => {
  const {companyData} = useActivatedContext().companyDataProvider;
  const translations = useTranslationsProvider({tenantData: companyData});
  return <WelcomeTitle tenantData={companyData} translations={translations} multiLine={props.multiLine} />;
};
