import React, {useContext} from 'react';
import {IDeviceProvider} from '../configuration/UseDevice';
import {IAuthenticator} from '../authenticator/UseAuthenticator';
import {IAuthenticatedApi} from '../api/UseAuthenticatedApi';
import {IUnauthenticatedApi} from '../api/UseUnauthenticatedApi';
import {IPubnubConnector} from '../pubnub/UsePubnubConnector';
import {IEnvironment} from '../configuration/IEnvironment';
import {IUseTenants} from '../configuration/UseTenants';
import {ICompanyDataProvider} from '../configuration/UseCompany';

const context = React.createContext<IActivatedContext>({} as IActivatedContext);

export interface IActivatedContext {
  authenticator: IAuthenticator;
  environment: IEnvironment;
  authenticatedApi: IAuthenticatedApi;
  unauthenticatedApi: IUnauthenticatedApi;
  deviceProvider: IDeviceProvider;
  companyDataProvider: ICompanyDataProvider;
  tenants: IUseTenants;
  pubnubConnector: IPubnubConnector;
  content: JSX.Element;
  initialize(): Promise<void>;
  initialized: boolean;
}

export const ActivatedContextProvider = (props: React.PropsWithChildren<{value: IActivatedContext}>) => {
  return <context.Provider value={props.value}>{props.children}</context.Provider>;
};

export function useActivatedContext(): IActivatedContext {
  return useContext(context);
}
