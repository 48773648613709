import React from 'react';
import {StyleSheet, Text} from 'react-native';
import {QR_CODE_CONTAINER_SIZE} from './CheckinQRCode';
import {ITenantData} from '../../common/api/models/ITenantData';

export interface IQrSubTextProps {
  subText: string;
  companyData: ITenantData;
}

export const QrSubText = (props: IQrSubTextProps) => {
  const textColor = props.companyData.kioskParameters.titleColor;
  return (
    <Text
      style={[
        styles.subtext,
        {
          color: textColor,
        },
      ]}>
      {props.subText}
    </Text>
  );
};

const styles = StyleSheet.create({
  subtext: {
    textAlign: 'center',
    maxWidth: QR_CODE_CONTAINER_SIZE,
    fontFamily: 'CircularStd-Book',
    fontWeight: 'normal',
    fontSize: 19,
  },
});
