import {MultiTenantCenterView} from './centerViews/MultiTenantCenterView';
import React, {useEffect, useState} from 'react';
import {Animated, Dimensions, StyleSheet, View} from 'react-native';
import {MultiTenantCenterViewWithGrid} from './centerViews/MultiTenantCenterViewWithGrid';
import {NotRegisteredButtonView} from './switchButtons/NotRegisteredButtonView';
import {RegisteredButtonView} from './switchButtons/RegisteredButtonView';
import {useCenterViewLayoutForMultiTenant} from './UseCenterViewLayoutForMultiTenant';
import {MultiTenantWelcomeTitle} from './title/MultiTenantWelcomeTitle';

export const MultiTenantView = () => {
  const {showCheckinQRCodeAndQRCodeReader} = useCenterViewLayoutForMultiTenant();

  const [showGrid, setShowGrid] = useState(false);
  const [animation] = useState(new Animated.Value(0));
  const offsetInterpolation = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -Dimensions.get('window').height],
  });

  useEffect(() => {
    const animationTiming = Animated.timing(animation, {
      toValue: showGrid ? 1 : 0,
      duration: 500,
      useNativeDriver: false,
    });
    animationTiming.start();
  }, [showGrid]);

  return (
    <View style={styles.container}>
      <Animated.View
        style={{
          transform: [
            {
              translateY: offsetInterpolation,
            },
          ],
        }}>
        <View style={styles.topView}>
          <View style={{height: Dimensions.get('window').height - 20}}>
            {showCheckinQRCodeAndQRCodeReader && <MultiTenantWelcomeTitle multiLine={false} />}
            <MultiTenantCenterView />
            {showCheckinQRCodeAndQRCodeReader && (
              <NotRegisteredButtonView
                onPress={async () => {
                  setShowGrid(true);
                }}
              />
            )}
          </View>
        </View>
        <View style={styles.bottomView}>
          <RegisteredButtonView
            onPress={async () => {
              setShowGrid(false);
            }}
          />
          <MultiTenantCenterViewWithGrid />
        </View>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {flex: 1},
  topView: {
    height: Dimensions.get('window').height,
  },
  bottomView: {
    justifyContent: 'center',
    height: Dimensions.get('window').height,
  },
});
