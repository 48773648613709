import {StyleSheet, Text, View} from 'react-native';
import React, {useState} from 'react';
import {ITranslationsProvider} from '../../common/translations/useTranslationsProvider';
import {ITenantData} from '../../common/api/models/ITenantData';
import {Sizes} from '@proxyclick/hello-react-native';

export interface WelcomeTitleProps {
  translations: ITranslationsProvider;
  tenantData: ITenantData;
  multiLine: boolean;
}

export const WelcomeTitle = (props: WelcomeTitleProps) => {
  const [multiLine] = useState<boolean>(props.multiLine);
  const {getTextForKey} = props.translations;
  const titleViewStyle = multiLine ? styles.titleView : styles.singleLineTitleView;
  return (
    <View style={titleViewStyle}>
      {!multiLine && (
        <Text
          allowFontScaling={true}
          adjustsFontSizeToFit
          numberOfLines={1}
          testID={'helloLabelId'}
          style={[
            styles.hello1,
            {
              color: props.tenantData.kioskParameters.titleColor,
            },
          ]}>
          {getTextForKey('vm_welcome_hello_line1') + ' ' + getTextForKey('vm_welcome_hello_line2')}
        </Text>
      )}
      {multiLine && (
        <Text
          numberOfLines={1}
          testID={'hello1LabelId'}
          style={[
            styles.hello1,
            {
              color: props.tenantData.kioskParameters.titleColor,
            },
          ]}>
          {getTextForKey('vm_welcome_hello_line1')}
        </Text>
      )}
      {multiLine && (
        <Text
          numberOfLines={1}
          testID={'hello2LabelId'}
          style={[
            styles.hello2,
            {
              color: props.tenantData.kioskParameters.titleColor,
            },
          ]}>
          {getTextForKey('vm_welcome_hello_line2')}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  titleView: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  singleLineTitleView: {
    marginTop: Sizes.l,
    justifyContent: 'center',
    alignItems: 'center',
  },
  hello1: {
    textAlign: 'left',
    fontFamily: 'CircularStd-Bold',
    fontWeight: 'bold',
    fontSize: 75,
  },
  hello2: {
    textAlign: 'left',
    fontFamily: 'CircularStd-Book',
    fontWeight: 'normal',
    fontSize: 75,
  },
});
