// TODO: This component needs to be moved to the hello component library
import React from 'react';
import {View, StyleSheet, ViewStyle, TextStyle, TouchableOpacity, Text} from 'react-native';
import {disabledOpacity, onPressOpacity, TextStyles} from '@proxyclick/hello-react-native';

export interface LabelButtonProps {
  textColor: string;
  text: string;
  onPress: () => void;
  // bordered button if false. (by default: true)
  isFilled?: boolean;
  // grays out button if false. (by default: true)
  isEnabled?: boolean;
  // button width fits label width if true or takes all available width if false. default: true
  widthFitsLabel?: boolean;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  // shows a loading icon. default: false
  isLoading?: boolean;
  containerStyle?: ViewStyle;
  testID?: string;
}

const LabelButton = (props: LabelButtonProps) => {
  const isEnabled = props.isEnabled ?? true;
  let fontColor = props.textColor;

  let containerStyle: ViewStyle[] = [
    props.containerStyle ?? {},
    {opacity: isEnabled ? 1 : disabledOpacity, marginRight: 40},
  ];
  let textStyle: TextStyle[] = [styles.text, {color: fontColor}];
  return (
    <View style={containerStyle}>
      <TouchableOpacity
        style={styles.touchableArea}
        activeOpacity={isEnabled ? onPressOpacity : 1}
        onPress={isEnabled ? props.onPress : undefined}
        testID={props.testID}>
        {props.iconLeft && <View style={styles.iconLeft}>{props.iconLeft}</View>}
        <Text style={textStyle}>{props.text}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    marginRight: 7,
    textAlign: 'center',
    ...TextStyles.textMediumS,
  },
  iconLeft: {
    // TODO: this size should be found in hello-component library
    marginRight: 7,
  },
  touchableArea: {flexDirection: 'row', flex: 1, alignItems: 'center'},
});

export default LabelButton;
