import {useMemo, useState} from 'react';
import {Environment, IEnvironment} from './IEnvironment';

export const useDashboardEnvironment = (): IEnvironment => {
  const [environment, setEnvironment] = useState<Environment>();

  const apiBaseUrl = useMemo(() => {
    return '/api/';
  }, []);

  const kioskBaseUrl = useMemo(() => {
    return '';
  }, []);

  return {
    environment,
    setEnvironment,
    apiBaseUrl,
    kioskBaseUrl,
  };
};
